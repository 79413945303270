<template>
  <ml-dialog
    width="640px"
    ref="dialogRef"
    :title="title"
    @click-submit="saveHandle"
    @click-close="closeHandle"
  >
    <template #body>
      <ml-form
        :model="itemData"
        ref="formRef"
        :rules="formRules"
        labelWidth="100px"
        style="width: 100%"
      >
        <ml-select
          prop="companyId"
          placeholder="请选择阿米巴"
          :options="unitOptions"
          label="阿米巴:"
          keyName="companyId"
          labelName="companyName"
          valueName="companyId"
          v-model="itemData.companyId"
          @change="selectUnit"
        />
        <!-- 所属项目 -->
        <ml-select
          prop="projectId"
          placeholder="请选择所属项目"
          :options="projectData"
          label="所属项目:"
          keyName="projectId"
          labelName="projectName"
          valueName="projectId"
          v-model="itemData.projectId"
        />
        <ml-input
          prop="siteAddr"
          label="站点目的地:"
          placeholder="请输入站点目的地"
          v-model.trim="itemData.siteAddr"
        />
        <ml-input
          prop="longitude"
          label="经度:"
          placeholder="请输入经度"
          v-model.trim="itemData.longitude"
        />
        <ml-input
          prop="latitude"
          label="纬度:"
          placeholder="请输入纬度"
          v-model.trim="itemData.latitude"
        />
      </ml-form>
    </template>
  </ml-dialog>
</template>

<script>
import { ref, reactive, onMounted, computed, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import { getTabberData, searchParams, tabberHeight, formetData } from '@/utils'
export default {
  props: {
    editData: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const { commit, getters, dispatch } = useStore()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const title = ref('新增站点')
    const dialogRef = ref(null)
    const openDialog = () => {
      dialogRef.value.open()
    }
    const closeHandle = () => {
      emit('close')
    }
    const itemData = reactive({
      id: '',
      companyId: '',
      projectId: '',
      siteAddr: '',
      longitude: '',
      latitude: '',
      createTime: ''
    })
    watch(
      () => props.editData,
      async newvalue => {
        if (Object.keys(newvalue).length) {
          title.value = '编辑站点'
          itemData.id = newvalue.id
          itemData.companyId = newvalue.companyId
          itemData.projectId = newvalue.projectId
          itemData.siteAddr = newvalue.siteAddr
          itemData.longitude = newvalue.longitude
          itemData.latitude = newvalue.latitude
          itemData.createTime = newvalue.createTime
          await nextTick()
          getProjectData(itemData.companyId)
        }
      },
      { immediate: true, deep: true }
    )
    const formRules = reactive({
      companyId: [{ required: true, message: '请选择阿米巴', trigger: 'blur' }],
      projectId: [{ required: true, message: '请选择所属项目', trigger: 'blur' }],
      siteAddr: [{ required: true, message: '请输入站点目的地', trigger: 'blur' }],
      longitude: [
        { required: true, message: '请输入经度', trigger: 'blur' },
        {
          pattern:
            /^-?(180(\.0{6,8})?|1[0-7][0-9](\.\d{6,8})?|[1-9][0-9](\.\d{6,8})?|[1-9](\.\d{6,8})?)$/,
          message: '经度格式不正确, 必须精确到6位小数',
          trigger: 'blur'
        }
      ],
      latitude: [
        { required: true, message: '请输入纬度', trigger: 'blur' },
        {
          pattern: /^-?(90(\.0{6,8})?|[1-8][0-9](\.\d{6,8})?|[1-9](\.\d{6,8})?)$/,
          message: '纬度格式不正确，必须精确到6位小数',
          trigger: 'blur'
        }
      ]
    })

    // 单位名下拉列表
    const unitOptions = ref([])
    const selectUnit = () => {
      itemData.projectId = ''
      projectData.value = []
    }
    watch(
      () => itemData.companyId,
      newvalue => {
        getProjectData(newvalue)
      }
    )
    // 所属项目配置
    const projectData = ref([])
    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = { projectStatus: 'Y' }
      if (isBusinessDepartment.value) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }
    const formRef = ref(null)
    const saveHandle = () => {
      formRef.value.CustomFormRef.validate().then(() => {
        dispatch('fetchInsetOrUpdatePcsSite', itemData).then(data => {
          if (data.code === 200) {
            commit('setError', {
              status: true,
              title: data.message,
              message: '正在获取新的数据...',
              type: 'success'
            })
            emit('save-success')
            closeHandle()
          }
        })
      })
    }
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {
        noCompanyCode: 'business_department'
      })
      unitOptions.value = data || []
    })

    return {
      title,
      openDialog,
      closeHandle,
      saveHandle,
      dialogRef,
      itemData,
      formRules,
      unitOptions,
      getProjectData,
      projectData,
      selectUnit,
      formRef
    }
  }
}
</script>