/**
 * 里程信息接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询角色信息
    async fetchGetPcsMileagePage({ commit, getters }, params) {
      const { getPcsMileagePage } = getters.getHttps
      const { data } = await getPcsMileagePage(params)
      return setStoreApiDate(data, commit)
    },
    // 新增或修改
    async fetchInsetOrUpdatePcsMileage({ commit, getters }, params) {
      const { insetOrUpdatePcsMileage } = getters.getHttps
      const { data } = await insetOrUpdatePcsMileage(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个角色信息
    async fetchGetPcsMileageInfo({ commit, getters }, params) {
      const { getPcsMileageInfo } = getters.getHttps
      const { data } = await getPcsMileageInfo(params)
      return setStoreApiDate(data, commit)
    },
    // 删除单个详情
    async fetchDeletePcsMileage({ commit, getters }, params) {
      const { deletePcsMileage } = getters.getHttps
      const { data } = await deletePcsMileage(params)
      return setStoreApiDate(data, commit)
    },
    // 站点列表导出
    async fetchMileageExportPcsMileage({ commit, getters }, params) {
      const { MileageExportPcsMileage } = getters.getHttps
      const { data } = await MileageExportPcsMileage(params)
      return setStoreApiDate(data, commit)
    }
  }
}
