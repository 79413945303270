<template>
  <div class="amoebaHead-view">
    <!-- 导航提示标题 -->
    <!-- <ml-tip :showHeader="false" content="设备图层" /> -->
    <!-- 数据表格 -->

    <ml-tip
      :showHeader="false"
      class="default-tip equipment-layer-tip clear-tip-pd"
      style="margin-bottom: 0"
    >
      <div class="tmap-box" :style="{ height: `${tabberHeight(isFull ? 1 : 90)}px` }">
        <!-- 标题 -->
        <div class="tmap-box-title">
          <div class="tmap-box-title-body">
            {{ amoebaHeadTitle }}
          </div>
        </div>
        <!-- 公告栏 -->
        <div class="tmap-box-NoticeBar">
          <div class="tmap-box-NoticeBar-line"></div>
          <div class="tmap-box-NoticeBar-body">
            <vue-seamless-scroll
              :data="[newCompanyNoticeRecordData['content']]"
              class="tmap-box-NoticeBar-body-scroll"
              :class-option="{
                step: 2,
                limitMoveNum: 1,
                direction: 2
              }"
            >
              <!-- <div :style="{ textAlign: 'center', margin: '20px 0' }">
                {{ newCompanyNoticeRecordData['title'] }}
              </div> -->

              {{ newCompanyNoticeRecordData['content'] }}
            </vue-seamless-scroll>
          </div>
          <div class="tmap-box-NoticeBar-line"></div>
        </div>

        <!-- 左侧 -->
        <div
          class="tmap-box-aside tmap-box-aside-lefttop"
          :class="{
            'tmap-box-aside-closeL': closeL
          }"
        >
          <!-- <div style="padding: 0 10px 0">
          </div> -->
          <div class="tmap-box-aside-item">
            <div class="tmap-box-text">运维概况</div>
            <div class="tmap-box-body">
              <operational-item :data="OperationalItemOption" />
            </div>
          </div>
          <div class="tmap-box-aside-item" :style="{ flex: 1.5 }">
            <div class="tmap-box-text">工单与巡检</div>
            <div class="tmap-box-body">
              <div class="tmap-box-body-ecbox">
                <ml-echarts id="a" :option="bNew([deviceErrorStatisticsData], 'a')" />
              </div>
              <div class="tmap-box-body-ecbox">
                <ml-echarts id="b" :option="bNew(inspectTypeTotalStatisticsData, 'b')" />
              </div>
            </div>
          </div>
          <div class="tmap-box-aside-item">
            <div class="tmap-box-text">故障等级</div>
            <div class="tmap-box-body">
              <ml-echarts id="c" :option="z1New([errorLevelCompanyStatisticsData])" />
            </div>
          </div>

          <div class="tmap-box-aside-close tmap-box-aside-line" @click="closeL = !closeL"></div>
        </div>

        <!-- 右侧 -->
        <div
          ref="rightViewRef"
          class="tmap-box-aside tmap-box-aside-righttop"
          :class="{
            'tmap-box-aside-closeR': closeR
          }"
        >
          <!-- <div style="padding: 0 10px 0">
          </div> -->
          <div class="tmap-box-aside-item">
            <div class="tmap-box-text">
              <span>项目信息</span>
              <span :style="{ cursor: 'pointer' }" @click="clickProjectView">查看</span>
            </div>
            <div class="tmap-box-body" :style="{ paddingTop: '10px', display: 'block' }">
              <el-table
                :show-header="false"
                :data="projectData"
                height="110"
                :style="{ marginTop: '10px' }"
                v-if="false"
                class="amoebaHead-view-table amoebaHead-view-table-pro"
              >
                <el-table-column prop="label" label="" />
                <el-table-column show-overflow-tooltip align="right" prop="value" label="" />
              </el-table>

              <div class="project-info">
                <div
                  class="info-item"
                  v-for="(item, index) in projectData"
                  :key="'project' + index"
                >
                  <div class="item-label">{{ item.label }}</div>
                  <div class="item-value">{{ item.value }}</div>
                </div>
              </div>

              <el-divider :style="{ background: '#264487', margin: '5px 0' }" />

              <el-table
                :show-header="false"
                :data="projectDataOne"
                height="172"
                class="amoebaHead-view-table amoebaHead-view-table-pro"
              >
                <el-table-column prop="label" label="" />
                <el-table-column show-overflow-tooltip align="right" prop="value" label="">
                  <template #default="{ row }">
                    <span v-if="row.show === 'hide'">******</span>
                    <span v-else>{{ row.value }}</span>
                    <el-image
                      v-if="row.show"
                      class="amoebaHead-view-table-pro-img"
                      :src="row.show === 'hide' ? showImg : hideImg"
                      fit="fill"
                      @click="row.show = row.show === 'show' ? 'hide' : 'show'"
                    ></el-image>
                  </template>
                </el-table-column>
              </el-table>

              <el-divider :style="{ background: '#264487', margin: '5px 0' }" />
              <el-table
                :show-header="false"
                :data="projectDataTow"
                height="110"
                class="amoebaHead-view-table amoebaHead-view-table-pro"
              >
                <el-table-column prop="label" label="" />
                <el-table-column show-overflow-tooltip align="right" prop="value" label="">
                  <template #default="{ row }">
                    <span @click="routeProject(row)">{{ row.value }}</span>
                    <span v-if="row.key !== 'serviceOrg'">/</span>
                    <span
                      v-if="row.key !== 'serviceOrg'"
                      :style="{ color: 'red' }"
                      @click="routeProject(row)"
                    >
                      {{ row.value1 }}
                    </span>
                  </template>
                </el-table-column>
              </el-table>
              <el-table
                v-if="false"
                :show-header="false"
                :data="projectDataThree"
                height="44"
                class="amoebaHead-view-table amoebaHead-view-table-pro"
              >
                <el-table-column prop="label" label="" />
                <el-table-column show-overflow-tooltip align="center" prop="value" label="">
                  <template #default="{ row }">
                    <el-button type="primary" size="default" @click="openResFn(row)">
                      打开文件夹
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="tmap-box-aside-close tmap-box-aside-line" @click="closeR = !closeR"></div>
        </div>

        <!-- 下面 -->
        <div
          ref="bottomViewRef"
          class="tmap-box-aside tmap-box-aside-bottom"
          :class="{
            'tmap-box-aside-closeB': closeB
          }"
        >
          <!-- <div style="padding: 0 10px 0">
          </div> -->
          <div class="tmap-box-aside-item" :style="{ flex: 1 }">
            <div class="tmap-box-text">待处理信息</div>
            <div class="tmap-box-body">
              <!-- 列表 -->
              <div class="error-box">
                <ul class="error-header">
                  <li>项目名称</li>
                  <li>类型</li>
                  <li>等级</li>
                  <li>标题</li>
                  <li>提交人</li>
                  <li>发生时间</li>
                  <li>操作</li>
                </ul>

                <div class="scroll-box">
                  <vue-seamless-scroll
                    :data="errorData"
                    :class-option="{
                      limitMoveNum: 2,
                      step: 0.4,
                      hoverStop: true
                    }"
                    v-if="errorData.length"
                  >
                    <div class="error-body" :style="`height:${errorData.length * 60}px;`">
                      <ul class="error-item" v-for="item in errorData" :key="item.id">
                        <li class="ml-line-over">{{ item.name }}</li>
                        <li class="ml-line-over">{{ item.typeName }}</li>
                        <li class="ml-line-over" :style="{ color: companyColors[item.severity] }">
                          {{ item.severity }}
                        </li>
                        <li class="ml-line-over">{{ item.title }}</li>
                        <li class="ml-line-over">{{ item.reportUser }}</li>
                        <li class="ml-line-over">{{ item.time }}</li>
                        <li>
                          <div>
                            <el-button
                              size="mini"
                              @click="goProjectDetail(item)"
                              v-if="item.status == 1"
                              >审核</el-button
                            >
                            <el-button size="mini" @click="goProjectDetail(item)" v-else
                              >处理</el-button
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </vue-seamless-scroll>

                  <div class="empty-box" v-else>暂无信息</div>
                </div>
              </div>

              <el-table :data="errorData" height="120px" class="amoebaHead-view-table" v-if="false">
                <el-table-column prop="name" label="项目名称" />
                <el-table-column prop="time" label="发生时间" />
                <el-table-column prop="typeName" label="类型" />
                <el-table-column prop="severity" label="等级" />
                <el-table-column prop="title" label="标题" />
                <el-table-column prop="reportUser" label="提交人" />
                <el-table-column prop="do" label="操作">
                  <template #default="{ row }">
                    <el-button size="mini" @click="goProjectDetail(row)" v-if="row.status == 1"
                      >审核</el-button
                    >
                    <el-button size="mini" @click="goProjectDetail(row)" v-else>处理</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="tmap-box-aside-item" :style="{ width: '335px' }">
            <div class="tmap-box-text">
              <span>今日值班</span>
              <span
                v-if="dutyData.length > 0"
                :style="{ cursor: 'pointer' }"
                @click="clickDutyView(dutyData[0])"
              >
                查看
              </span>
            </div>
            <div class="tmap-box-body">
              <el-table
                empty-text="暂无值班"
                :data="dutyData"
                height="100px"
                class="amoebaHead-view-table today-duty"
              >
                <el-table-column align="center" prop="className" label="班次" />
                <el-table-column
                  show-overflow-tooltip
                  align="center"
                  prop="userName"
                  label="值班人员"
                />
                <el-table-column align="center" prop="startTime" label="开始时间" />
                <el-table-column align="center" prop="endTime" label="结束时间" />
              </el-table>
            </div>
          </div>
          <div class="tmap-box-aside-close tmap-box-aside-line" @click="closeB = !closeB"></div>
        </div>

        <div class="tmap-box-main">
          <t-amp
            id="projectLayerId"
            ref="tMapRef"
            :list="projectList"
            @click-amoeba-head-markers="clickAmoebaHeadMarkers"
            @view-prview-project="handMaker"
            mapStyle=""
          />
          <div class="tmap-box-main-legend" ref="legendViewRef">
            <el-image :src="circleB" fit="fill" :style="{ width: '30px', height: '30px' }" />
            <span class="tmap-box-main-legend-text">正常</span>
            <el-image :src="circleR" fit="fill" :style="{ width: '30px', height: '30px' }" />
            <span class="tmap-box-main-legend-text">故障</span>
            <el-image :src="circleG" fit="fill" :style="{ width: '30px', height: '30px' }" />
            <span class="tmap-box-main-legend-text">过期</span>
          </div>
        </div>
      </div>
    </ml-tip>
  </div>
</template>

<script>
import { computed, inject, nextTick, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import { getTabberData, tabberHeight, formetData } from '@utils'
import TAmp from '../../components/tamap.vue'
import OperationalItem from './components/perationalItem.vue'
import bNew from '../../utils/viewData/bNew'
import z1New from '../../utils/viewData/z1New'
import showImg from '../../assets/image/show.png'
import hideImg from '../../assets/image/hide.png'
import circleG from '../../assets/image/map/项目-g.png'
import circleR from '../../assets/image/map/项目-list.png'
import circleB from '../../assets/image/map/项目.png'
import { useStore } from 'vuex'
import vueSeamlessScroll from 'vue-seamless-scroll/src'
import { useRouter } from 'vue-router'

export default {
  name: 'AmoebaHeadView',
  components: {
    TAmp,
    OperationalItem,
    vueSeamlessScroll
  },
  setup() {
    const router = useRouter()
    const { dispatch, getters } = useStore()
    const userInfo = computed(() => getters.getUser)
    const isFull = inject('isFull')
    const amoebaHeadTitle = ref(`${userInfo.value.companyName || ''}运维概况`)
    watch(
      () => userInfo.value,
      newvalue => {
        amoebaHeadTitle.value = `${newvalue.companyName || ''}运维概况`
      }
    )
    const rightViewRef = ref()
    const bottomViewRef = ref()
    const legendViewRef = ref()
    const closeL = ref(false)
    const closeR = ref(false)
    const closeB = ref(false)

    watch([() => closeR.value, () => closeB.value], newvalue => {
      nextTick(() => {
        legendViewRef.value.style.right = newvalue[0] ? '10px' : '400px'
        legendViewRef.value.style.bottom = newvalue[1]
          ? '10px'
          : `${bottomViewRef.value.offsetHeight}px`
      })
    })

    const OperationalItemOption = ref([
      { label: '服务客户', value: 0, key: 'projectTotal' },
      { label: '运维人数', value: 0, key: 'userTotal' },
      { label: '资产总数', value: 0, key: 'deviceTotal' },
      { label: '受监控设备', value: 0, key: 'monitorTotal' },
      { label: '投诉', value: 0, key: 'suggestTotal' },
      { label: '满意度', value: 0, key: 'score' },
      { label: '合同绩效', value: 0, key: 'contractAmount', show: 'hide' }
    ])

    const getprojectDeviceTotalStatistics = async () => {
      const { data } = await getTabberData(dispatch, 'fetchgetprojectDeviceTotalStatistics')
      OperationalItemOption.value.map(item => {
        // 满意度
        if (item.key == 'score') {
          item.value = (data[item['key']] || 0).toFixed(2)
        } else {
          item.value = data[item['key']] || 0
        }
      })

      // console.log(OperationalItemOption.value)
    }

    // 故障状态数量统计
    const deviceErrorStatisticsData = reactive({ completeCount: 0, undoCount: 0 })
    const getdeviceErrorStatistics = async () => {
      const { data } = await getTabberData(dispatch, 'fetchgetdeviceErrorStatistics')
      deviceErrorStatisticsData.completeCount = data.completeCount || 0
      deviceErrorStatisticsData.undoCount = data.undoCount || 0
      deviceErrorStatisticsData.errorCount = data.completeCount + data.undoCount
    }
    // 故障状态数量统计
    const inspectTypeTotalStatisticsData = ref([])
    const getinspectTypeTotalStatistics = async () => {
      const { data } = await getTabberData(dispatch, 'fetchgetinspectTypeTotalStatistics')
      inspectTypeTotalStatisticsData.value = data || []

      // console.log(bNew(inspectTypeTotalStatisticsData.value, 'b'))
    }
    // fetchgeterrorLevelCompanyStatistics
    const errorLevelCompanyStatisticsData = ref([])
    const geterrorLevelCompanyStatistics = async () => {
      const { data } = await getTabberData(dispatch, 'fetchgeterrorLevelCompanyStatistics')
      errorLevelCompanyStatisticsData.value = data || []
    }

    // 获取项目
    const projectId = ref('')
    const projectIndex = ref(-1)
    const projectList = ref([])
    const getProjectInfos = async () => {
      // const { city, companyId } = mapLabelData.value
      // if ((city, companyId)) {
      //   params.city = city
      //   params.companyId = companyId
      // }
      const data = await dispatch('fetchGetProjectsByCompany')
      if (data && data.code === 200) {
        // commit('setmapLabelData', {})
        const nData = data.data || []
        projectList.value = nData
        projectId.value = nData.length > 0 ? nData[0].projectId : ''
        projectIndex.value = nData.length > 0 ? 0 : -1
        animation()
      }
    }
    // length 5 pIndex 3
    const timeOut = ref()
    const animation = () => {
      clearTimeout(timeOut.value)
      timeOut.value = setTimeout(() => {
        const len = projectList.value.length
        if (len > 0) {
          projectIndex.value = projectIndex.value + 1
          if (projectIndex.value >= len) {
            projectIndex.value = 0
          }
          projectId.value = projectList.value[projectIndex.value].projectId
        }
        animation()
        // 120000
      }, 10000)
    }

    watch(
      () => projectId.value,
      newvalue => {
        if (newvalue) {
          getDatas(newvalue)
        }
      }
    )

    const errorData = ref([])
    const dutyData = ref([])
    const projectData = ref([
      { label: '项目名称:', value: '', key: 'projectName' },
      { label: '项目编导:', value: '', key: 'projectCode' },
      // { label: '项目类型:', value: '', key:'projectType' },
      { label: '项目经理:', value: '', key: 'projectManager' },
      // { label: '项目经理电话:', value: '', key: 'managerPhone' },
      { label: '业主负责人:', value: '', key: 'proprietor' }
      // { label: '负责人电话:', value: '', key: 'leaderPhone' }
    ])
    const projectDataOne = ref([
      { label: '服务开始时间:', value: '', key: 'serviceStartTime' },
      { label: '服务结束时间:', value: '', key: 'serviceEndTime' },
      { label: '项目地址:', value: '', key: 'address' },
      // { label: '主要服务人员:', value: '', key: 'mainOpsUser' },
      { label: '运维费用:', value: '0', show: 'show', key: 'opsExpenses' },
      { label: '累计付款:', value: '0', show: 'show', key: 'paymentTotal' },
      { label: '专项费用:', value: '0', show: 'show', key: 'specialExpenses' }
    ])
    const projectDataTow = ref([
      { label: '运维（组织机构）:', value: '', key: 'serviceOrg' },
      { label: '资产设备:', value: '0', value1: '0', key: 'deviceTotal', key1: 'monitorTotal' },
      { label: '故障:', value: '0', value1: '0', key: 'errorTotal', key1: 'errorUndoTotal' },
      { label: '巡检:', value: '0', value1: '0', key: 'inspectTotal', key1: 'inspectUndoTotal' }
    ])
    const projectDataThree = ref([{ label: '项目文件:', value: '', key: 'projectId' }])

    // 跳转项目详情
    const clickProjectView = () => {
      if (projectId.value) {
        router.push({ path: '/projectInfoManageAction', query: { viewId: projectId.value } })
      }
    }

    // 跳转项目列表
    const routeProject = row => {
      // console.log(row)
      // 设备
      if (row.key == 'deviceTotal') {
        router.push({
          path: '/equipmentManage'
        })
      } else if (row.key == 'errorTotal') {
        //故障

        router.push({
          path: '/faultServiceCenter'
        })
      } else if (row.key == 'inspectTotal') {
        //巡检

        router.push({
          path: '/inspectTasks'
        })
      }
    }

    // 跳转值班详情
    const clickDutyView = row => {
      const { dutyId } = row
      if (dutyId) {
        const nDate = formetData(new Date(), 'year')
        const [year, month, day] = nDate.split('-')
        router.push({
          path: '/dutyInfosManagetDetails',
          query: { dutyId, year, month, day }
        })
      }
    }

    // 故障公告
    const newCompanyNoticeRecordData = ref({})
    const newCompanyNoticeRecord = async () => {
      const { data } = await getTabberData(dispatch, 'fetchNewCompanyNoticeRecord')
      newCompanyNoticeRecordData.value = data || {}
    }

    // 待处理故障等级颜色
    const companyColors = {
      轻微: '#435CFE',
      一般: '#34B340',
      严重: '#FFA903',
      灾难: '#FF6353'
    }
    // 查询待处理列表
    const getPendingList = () => {
      const companyId = userInfo.value.companyId
      dispatch('fetchGetDeviceErrorInspectList', { companyId }).then(res => {
        if (res.code == 200) {
          const data = res.data || []
          errorData.value = data
        }
      })
    }
    // 待处理信息 跳转
    const goProjectDetail = row => {
      // 巡检 待处理
      if (row.type == 1) {
        if (row.historyData === '1') { 
          router.push({
            path: '/inspectTasksDetails',
            query: { inspectTaskId: row.id }
          })
        } else {
          router.push({
            path: '/newInspectTasksDetails',
            query: { inspectTaskId: row.id }
          })
        }
      } else {
        if (row.historyData === '1') {
          //故障 待审核
          router.push({
            path: '/faultServiceCenterDetails',
            query: { errorId: row.id }
          })
        } else {
          //故障 待审核
          router.push({
            path: '/newFaultServiceCenterDetails',
            query: { errorId: row.id }
          })
        }
      }
    }

    // 项目详情
    const getProjectInfo = projectId => {
      if (projectId) {
        dispatch('fetchGetProjectInfo', projectId).then(data => {
          if (data && data.code === 200) {
            const nData = data.data || {}
            projectData.value.map(item => {
              item.value = nData[item.key] || ''
              return item
            })
            projectDataOne.value.map(item => {
              // 费用
              const priceNames = ['opsExpenses', 'paymentTotal', 'specialExpenses']
              item.value = nData[item.key] || (priceNames.includes(item.key) ? '0' : '')

              if (item.key == 'serviceStartTime' || item.key == 'serviceEndTime') {
                const times = item.value.split(' ')
                item.value = times[0]
              }

              // 费用 默认星号
              if (priceNames.includes(item.key)) {
                item.show = 'hide'
              }

              return item
            })
            // console.log(projectDataOne.value)
            projectDataTow.value.map(item => {
              if (item.key === 'serviceOrg') {
                item.value = nData[item.key] || ''
                return item
              }
            })
          }
        })
      }
    }
    // 项目设备故障巡检数量统计
    const getdeviceErrorInspectTotal = projectId => {
      if (projectId) {
        dispatch('fetchgetdeviceErrorInspectTotal', { projectId }).then(data => {
          if (data && data.code === 200) {
            const nData = data.data || {}
            projectDataTow.value.map(item => {
              if (item.key !== 'serviceOrg') {
                item.value = nData[item.key1] || 0
                item.value1 = nData[item.key] || 0
                return item
              }
            })
          }
        })
      }
    }
    // 今天值班
    const gettodayDutyClassUser = projectId => {
      if (projectId) {
        dispatch('fetchgettodayDutyClassUser', { projectId }).then(data => {
          if (data && data.code === 200) {
            const nData = data.data || []
            dutyData.value = nData
          }
        })
      }
    }

    // 点击地图标注
    const clickAmoebaHeadMarkers = id => {
      clearTimeout(timeOut.value)
      projectId.value = id
      animation()
    }

    const handMaker = id => {
      // console.log(id)
      clearTimeout(timeOut.value)
      projectId.value = id
    }

    const getDatas = async projectId => {
      // console.log(projectId)
      await getProjectInfo(projectId)
      await getdeviceErrorInspectTotal(projectId)
      await gettodayDutyClassUser(projectId)
    }
    onMounted(async () => {
      // 获取单位名
      // const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      // unitOptions.value = data || []
      // await getequipmentTreeList()
      legendViewRef.value.style.right = closeR.value ? '10px' : '400px'
      legendViewRef.value.style.bottom = closeB.value
        ? '10px'
        : `${bottomViewRef.value.offsetHeight}px`

      getProjectInfos()
      newCompanyNoticeRecord()
      getprojectDeviceTotalStatistics()
      getdeviceErrorStatistics()
      getinspectTypeTotalStatistics()
      geterrorLevelCompanyStatistics()
      getPendingList()
    })
    onUnmounted(() => {
      clearTimeout(timeOut.value)
    })
    return {
      isFull,
      tabberHeight,
      amoebaHeadTitle,
      closeL,
      closeR,
      closeB,
      OperationalItemOption,
      bNew,
      z1New,
      errorData,
      projectData,
      projectDataOne,
      projectDataTow,
      projectDataThree,
      showImg,
      hideImg,
      circleG,
      circleR,
      circleB,
      newCompanyNoticeRecordData,
      deviceErrorStatisticsData,
      inspectTypeTotalStatisticsData,
      errorLevelCompanyStatisticsData,
      rightViewRef,
      bottomViewRef,
      legendViewRef,
      projectList,
      clickAmoebaHeadMarkers,
      clickProjectView,
      clickDutyView,
      dutyData,
      goProjectDetail,
      handMaker,
      routeProject,
      companyColors
    }
  }
}
</script>

<style lang="scss">
.amoebaHead-view {
  position: relative;

  .projectName {
    .el-table__row {
      display: grid;
      grid-template-columns: 100px 1fr;
    }
  }

  .tmap-box {
    &-text {
      font-size: 12px;
      color: #93b2ff;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
    }

    &-title {
      height: 66px;
      position: absolute;
      left: 0;
      top: 10px;
      width: 100%;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;

      &-body {
        height: 49px;
        width: 922px;
        background: url('~@image/viewTitle.png') no-repeat 100%/100%;
        color: #fff;
        font-weight: bold;
        font-size: 24px;
        text-align: center;
      }
    }

    &-NoticeBar {
      position: absolute;
      left: 0;
      top: 76px;
      width: 100%;
      z-index: 2;

      &-line {
        height: 1px;
        background: linear-gradient(
          to right,
          transparent 20%,
          rgba(85, 179, 253, 1) 30%,
          rgba(85, 179, 253, 1) 70%,
          transparent 80%
        );
      }

      &-body {
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding: 0 20px;
        background: linear-gradient(
          to right,
          transparent 0%,
          rgba(15, 34, 98, 0.9) 30%,
          rgba(15, 34, 98, 0.9) 70%,
          transparent 100%
        );

        &-scroll {
          > div {
            white-space: nowrap;
          }
        }
      }
    }

    &-aside {
      background: transparent;
      transition: all 0.5s;

      &-closeL {
        left: -395px !important;
      }

      &-closeR {
        right: -374px !important;
      }

      &-closeB {
        bottom: calc(-35% + 140px) !important;
      }

      &-line {
        width: 7px;
        height: 127px;
        right: 0;
        background: url('~@image/ss.png') no-repeat 100%/100%;
      }

      &-item {
        flex: 1;
        background: rgba(15, 35, 99, 0.6);
        margin: 5px 10px;
        border: 15px solid;
        border-image-source: url('~@image/viewbox.png');
        border-image-slice: 27;
        color: #fff;
        display: flex;
        flex-direction: column;

        .tmap-box-body {
          overflow-y: auto;
          max-height: 505px;

          // 待处理信息
          .error-box {
            display: grid;
            grid-template-rows: 30px 1fr;
            width: 100%;
            height: 100%;
            overflow: hidden;

            ul {
              width: 100%;
              padding: 0;
              list-style: none;
              margin: 0;
            }

            .error-header {
              font-size: 15px;
              font-weight: 700;
            }

            .scroll-box {
              height: 92px;
              overflow: hidden;

              .error-body {
                overflow: hidden;

                .error-item {
                  height: 60px;

                  .ml-line-over {
                    -webkit-line-clamp: 2;
                  }
                }
              }

              // 缺省
              .empty-box {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              }
            }

            .error-header,
            .error-item {
              grid-template-columns: 400px 150px 100px 150px 200px repeat(2, 1fr);
              display: grid;
              align-content: center;
              align-items: center;
              text-align: center;
            }
          }
        }

        // 项目信息
        .project-info {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;

          .info-item {
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: start;
            line-height: 25px;
            display: grid;
            grid-template-columns: 85px 1fr;
            padding: 0 10px;

            .item-value {
              text-align: right;
              font-size: 13px;
            }
          }
        }
      }

      &-lefttop {
        height: 65%;
        width: 400px;
        top: 130px;
        display: flex;
        left: 0;
        flex-direction: column;
      }

      &-righttop {
        height: 65%;
        width: 384px;
        top: 130px;
        right: 0;
        display: flex;
        flex-direction: column;

        > .tmap-box-aside-line {
          left: 0;
          transform: rotateZ(180deg);
        }

        > .tmap-box-aside-item {
          border-image-source: url('~@image/viewbox-1.png');
          border-image-slice: 45;
        }
      }

      &-bottom {
        height: calc(35% - 130px);
        width: 100%;
        bottom: 0px;
        left: 0;
        display: flex;

        > .tmap-box-aside-line {
          top: -60px;
          left: calc(100% / 2 - 63px);
          transform: rotateZ(-90deg);
        }

        > .tmap-box-aside-item {
          border-image-source: url('~@image/viewbox-1.png');
          border-image-slice: 45;
          flex: none;
        }
      }
    }

    &-body {
      flex: 1;
      display: flex;
      flex-wrap: wrap;

      &-ecbox {
        flex: 1;
      }
    }

    &-main {
      &-legend {
        transition: all 0.5s;
        position: absolute;
        bottom: 220px;
        right: 400px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 400;

        &-text {
          color: #fff;
          font-size: 16px;
          margin: 0 10px;
        }
      }
    }
  }

  &-table {
    background: transparent;
    color: #fff;

    th {
      color: #93b2ff;
    }

    td,
    th.is-leaf {
      border: none;
    }

    th,
    tr {
      background: transparent;
    }

    .el-table__empty-text {
      color: #fff !important;
    }

    .el-table__body tr:hover > td,
    &::after,
    &::before {
      background: transparent !important;
    }

    &-pro {
      th,
      td {
        padding: 2px 0;
      }

      &-img {
        width: 14px;
        margin-left: 5px;
        vertical-align: middle;
        // position: absolute;
        // right: 10px;
      }
    }
  }
}

.today-duty {
  &.el-table td {
    padding: 0 0;
  }
}
</style>
