/**
 * 站点里程管理接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询角色信息
    async fetchGetPcsSitePage({ commit, getters }, params) {
      const { getPcsSitePage } = getters.getHttps
      const { data } = await getPcsSitePage(params)
      return setStoreApiDate(data, commit)
    },
    // 新增或修改
    async fetchInsetOrUpdatePcsSite({ commit, getters }, params) {
      const { insetOrUpdatePcsSite } = getters.getHttps
      const { data } = await insetOrUpdatePcsSite(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个角色信息
    async fetchGetPcsSiteInfo({ commit, getters }, params) {
      const { getPcsSiteInfo } = getters.getHttps
      const { data } = await getPcsSiteInfo(params)
      return setStoreApiDate(data, commit)
    },
    // 删除单个详情
    async fetchDeletePcsSite({ commit, getters }, params) {
      const { deletePcsSite } = getters.getHttps
      const { data } = await deletePcsSite(params)
      return setStoreApiDate(data, commit)
    },
    // 站点列表导出
    async fetchSiteExportPcsSite({ commit, getters }, params) {
      const { siteExportPcsSite } = getters.getHttps
      const { data } = await siteExportPcsSite(params)
      return setStoreApiDate(data, commit)
    },
    // 站点列表导入
    async fetchSiteImportPcsSite({ commit, getters }, params) {
      const { siteImportPcsSite } = getters.getHttps
      const { data } = await siteImportPcsSite(params)
      return setStoreApiDate(data, commit)
    },
    // 根据故障ID获取里程记录列表
    async fetchListByErrorIdPcs({ commit, getters }, params) {
      const { listByErrorIdPcs } = getters.getHttps
      const { data } = await listByErrorIdPcs(params)
      return setStoreApiDate(data, commit)
    }
  }
}
