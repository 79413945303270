/**
 * 站点位置管理接口
 */

const prefix = '/dseWeb'
export default [
  // 查询列表（分页）
  { name: 'getPcsMileagePage', url: `${prefix}/v1/op/pcsMileage/page`, method: 'post' },
  // 新增或修改
  {
    name: 'insetOrUpdatePcsMileage',
    url: `${prefix}/v1/op/pcsMileage/insetOrUpdate`,
    method: 'post'
  },
  // 查询单个详情
  { name: 'getPcsMileageInfo', url: `${prefix}/v1/op//pcsMileage/info`, method: 'get' },
  // 删除单个详情
  { name: 'deletePcsMileage', url: `${prefix}/v1/op/pcsMileage/delete`, method: 'get' },
  // 列表导出
  {
    name: 'MileageExportPcsMileage',
    url: `${prefix}/v1/op/pcsMileage/export`,
    method: 'post'
  },
]
