<template>
  <task-detail typeTitle="校正"></task-detail>
</template>

<script>
import taskDetail from '../components/taskDetail.vue';
export default {
  components: {
    taskDetail
  },
}
</script>
