<template>
  <div class="site-location-manage">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="里程台账" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="100px" :model="searchData" ref="searchDataRef">
        <!-- 阿米巴 -->
        <ml-select
          prop="companyId"
          placeholder="请选择阿米巴"
          :options="unitOptions"
          label="阿米巴:"
          keyName="companyId"
          labelName="companyName"
          valueName="companyId"
          v-model="searchData.companyId"
          style="margin-bottom: 0"
          @change="selectUnit"
        />
        <!-- 所属项目 -->
        <ml-select
          prop="projectId"
          placeholder="请选所属项目"
          :options="projectData"
          label="所属项目:"
          keyName="projectId"
          labelName="projectName"
          valueName="projectId"
          v-model="searchData.projectId"
          :style="{ marginBottom: 0 }"
          @change="searchFn"
        />
        <!-- 名称 -->
        <ml-input
          prop="userName"
          placeholder="请输入人员名称"
          style="margin-bottom: 0"
          label="人员名称:"
          v-model="searchData.userName"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
      </ml-form>
      <div class="add-btn-box">
        <el-button icon="el-icon-download" type="primary" @click="mileageExcelExport">
          导出
        </el-button>
      </div>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(360)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="阿米巴"
          prop="companyName"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="所属项目"
          prop="projectName"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="业务类型"
          prop="businessTypeName"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="工单标题"
          prop="title"
          min-width="15%"
        >
          <template #default="scope">
            <span class="oper-btn" @click="toErrorDetail(scope.row)">{{ scope.row.title }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="车牌号"
          prop="carNumber"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="运维人员"
          prop="userName"
          min-width="15%"
        >
          <template #default="scope">
            <span>{{ scope.row.userName }}</span>
            <!-- <span
              v-for="(item, index) in scope.row.byCarUserVos"
              :key="index"
              class="car-user-item"
              >{{ item.userName }}</span
            > -->
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="出发地"
          prop="startAddr"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="目的地"
          prop="endAddr"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="里程数(km)"
          prop="actualMileage"
          min-width="15%"
        />
        <el-table-column align="center" label="操作" min-width="10%">
          <template #default="scope">
            <el-button
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="clickDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>
    <!-- 删除提示框 -->
    <ml-dialog
      ref="deleteUserDialogRef"
      :content="deleteUserData.content"
      @click-submit="submitDeleteUser"
    />
  </div>
</template>
<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { exportPcsMileageRequest } from '@/API/previewApi'
import {
  tabberHeight,
  searchParams,
  getTabberData,
  clearMemoryPageFn,
  replacePerCent,
  downloadFile
} from '@/utils'
export default {
  setup() {
    const router = useRouter()
    const { commit, getters, dispatch } = useStore()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const getMemoryPage = computed(() => getters.getMemoryPage)
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      userName: getMemoryPage.value.searchData.userName || '',
      companyId: getMemoryPage.value.searchData.companyId || '',
      projectId: getMemoryPage.value.searchData.projectId || ''
    })
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: getMemoryPage.value.page || 1,
      size: getMemoryPage.value.size || 10
    })
    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      clearMemoryPageFn(commit, searchData)
      tabberData.page === 1 ? getMileagePage() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getMileagePage() : (tabberData.page = 1)
    }
    // 单位配置
    const unitOptions = ref([])
    // 所属项目配置
    const projectData = ref([])
    watch(
      () => searchData.companyId,
      newvalue => {
        if (newvalue) {
          getProjectData(newvalue)
        } else {
          projectData.value = []
        }
      }
    )
    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value && companyId) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }

    /* 开始 数据列表 **********************************/
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getMileagePage()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }
    // 获取列表
    const getMileagePage = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData)
      searchDataParams.userName = replacePerCent(searchData.userName)
      const { data } = await getTabberData(
        dispatch,
        'fetchGetPcsMileagePage',
        params,
        searchDataParams
      )
      tabberData.data = data.records || []
      tabberData.total = data.total || 0
    }
    const toErrorDetail = row => {
      if (row.businessType === 'device_error') {
        router.push({ path: '/newFaultServiceCenterDetails', query: { errorId: row.errorId } })
      } else if (row.businessType === 'inspect_task') {
        getFaultServiceCenterDetails(row.errorId)
        // router.push({ path: '/newInspectTasksDetails', query: { inspectTaskId: row.errorId } })
      }
    }
    // 获取巡检详情
    const getFaultServiceCenterDetails = async inspectTaskId => {
      // 查看故障详情
      await dispatch('fetchGetInspectTask', inspectTaskId).then(data => {
        if (data && data.code === 200 && data.data) {
          let inspectData = data.data
          let path = '/newInspectTasksDetails'
          if (inspectData.qualityControlType === '标样核查') {
            path = '/byTaskDetail'
          } else if (inspectData.qualityControlType === '校正') {
            path = '/jzTaskDetail'
          } else if (inspectData.qualityControlType === '多点线性核查') {
            path = '/ddTaskDetail'
          } else if (inspectData.qualityControlType === '水样对比') {
            path = '/syTaskDetail'
          } else {
            path = '/newInspectTasksDetails'
          }
          router.push({ path, query: { inspectTaskId } })
        }
      })
    }
    // 删除
    const deleteUserDialogRef = ref()
    const deleteUserData = reactive({
      content: '确定删除该里程吗？',
      id: ''
    })

    const clickDelete = (index, row) => {
      deleteUserData.id = row.id
      deleteUserDialogRef.value.showDialog = true
    }
    // 确定删除
    const submitDeleteUser = () => {
      const { id } = deleteUserData
      dispatch('fetchDeletePcsMileage', { id: id }).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          tabberData.page === 1 ? getMileagePage() : (tabberData.page = 1)
          deleteUserDialogRef.value.showDialog = false
        }
      })
    }

    // 导出
    const mileageExcelExport = () => {
      exportPcsMileageRequest(searchData)
        .then(res => {
          let fileName = '里程台账.xlsx'
          downloadFile(res.data, fileName)
        })
        .catch(error => {})
    }

    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {
        noCompanyCode: 'business_department'
      })
      unitOptions.value = data || []
      getMileagePage()
    })
    return {
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      getMileagePage,
      tabberData,
      tabberHeight,
      currentChange,
      sizeChange,
      unitOptions,
      getProjectData,
      projectData,
      toErrorDetail,
      clickDelete,
      submitDeleteUser,
      deleteUserDialogRef,
      deleteUserData,
      mileageExcelExport
    }
  }
}
</script>
<style lang="scss" scoped>
.site-location-manage {
  .add-btn-box {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}
.car-user-item + .car-user-item {
  margin-left: 8px;
}
.oper-btn {
  cursor: pointer;
  color: #409eff;
}
.oper-btn:hover {
  text-decoration: underline;
}
</style>
