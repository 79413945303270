<template>
  <layer-page typeTitle="标样核查"></layer-page>
</template>

<script>
import layerPage from '../components/layerPage.vue'
export default {
  components: {
    layerPage
  }
}
</script>
