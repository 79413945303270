<template>
  <div class="project-info-manage">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="项目信息管理" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0" class="clear-tip-pd-b">
      <ml-form inline labelWidth="80px" :model="searchData" ref="searchDataRef">
        <el-row :gutter="20">
          <el-col :span="24" :offset="0">
            <ml-select
              prop="companyId"
              placeholder="请选择阿米巴"
              :options="unitOptions"
              label="阿米巴:"
              keyName="companyId"
              labelName="companyName"
              valueName="companyId"
              v-model="searchData.companyId"
              @change="searchFn"
            />
            <ml-select
              prop="projectStatus"
              placeholder="请选择服务状态"
              :options="statusOptions"
              label="服务状态:"
              keyName="value"
              labelName="name"
              valueName="value"
              v-model="searchData.projectStatus"
              @change="searchFn"
            />
            <!-- 关键词 -->
            <ml-input
              prop="searchName"
              placeholder="请输入合同名称/项目名称/项目经理"
              style="margin-bottom: 0; margin-right: 40px"
              label="关键词:"
              v-model="searchData.searchName"
            />

            <!-- 搜索按钮 -->
            <ml-button
              :style="{ 'margin-bottom': 0 }"
              submitText="搜索"
              submitIcon="el-icon-search"
              cancelText="重置"
              cancelIcon="el-icon-refresh"
              cancelType="danger"
              @click-cancel="resetSearchData"
              @click-submit="searchFn"
            />

            <!-- 添加 -->
            <ml-button
              :style="{ 'margin-bottom': 0 }"
              :showSubmit="false"
              cancelText="添加项目"
              cancelIcon="el-icon-plus"
              cancelType="primary"
              @click-cancel="clickPush"
            />
            <!-- 按钮 -->
            <ml-button
              :style="{ 'margin-bottom': 0 }"
              submitText="导出"
              submitIcon="el-icon-download"
              @click-submit="errorRecordbleDown"
              :showCancel="false"
            />
          </el-col>
          <el-col :span="12" :offset="0" :style="{ marginBotton: 0 }">
            有效合同项目
            <span style="color: green">{{ projectTotalStatisticsData.projectTotal }}</span>
            无效合同项目
            <span style="color: red; marginright: '20px'">{{
              projectTotalStatisticsData.total - projectTotalStatisticsData.projectTotal
            }}</span>
          </el-col>
          <el-col :span="12" :offset="0" :style="{ textAlign: 'right' }"> </el-col>
        </el-row>

        <!-- 阿米巴 -->
      </ml-form>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table
        :data="tabberData.data"
        style="width: 100%"
        :height="tabberHeight(339)"
        @select-all="selectAll"
        @select="select"
        :row-key="row => row.projectId"
        ref="errorRecordbleRef"
      >
        <el-table-column type="selection" :reserve-selection="true" width="55" />
        <!-- <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column> -->
        <el-table-column
          v-if="isBusinessDepartment"
          align="center"
          label="阿米巴"
          prop="companyName"
          min-width="15%"
        />
        <el-table-column align="center" label="项目编号" prop="projectCode" min-width="15%" />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="项目名称"
          prop="projectName"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="项目地址"
          prop="address"
          width="150"
        />
        <el-table-column align="center" label="业主负责人" prop="proprietor" width="100" />
        <el-table-column align="center" label="运维机构" prop="serviceOrg" min-width="10%" />
        <el-table-column align="center" label="项目经理" prop="projectManager" width="80" />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="建设单位"
          prop="constructionUnit"
          min-width="15%"
        />
        <!-- <el-table-column align="center" label="主要服务人员" prop="mainOpsUser" min-width="10%" /> -->
        <el-table-column align="center" label="服务人数" prop="serviceCount" min-width="10%" />
        <el-table-column align="center" label="设备数量" prop="deviceCount" min-width="10%" />
        <el-table-column align="center" label="服务状态" prop="status" min-width="10%">
          <template #default="scope">
            <span v-if="scope.row.status === 'Y'">有效</span>
            <span v-else :style="{ color: 'red' }">无效</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" priop="projectId" width="300px">
          <template #default="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              type="primary"
              @click="clickUpdate(scope.$index, scope.row)"
            >
              修改
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="clickDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-delete"
              type="success"
              @click="clickMigration(scope.$index, scope.row)"
            >
              迁移
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>
    <!-- 删除提示框 -->
    <ml-dialog
      ref="deleteProjectInfoDialogRef"
      :content="deleteProjectInfoData.content"
      @click-submit="submitDeleteProjectInfo"
    />
    <!-- 批量导出设备提示框 -->
    <ml-dialog
      ref="errorRecordbleDialogDialogRef"
      :content="errorRecordbleDialogDialogData.content"
      :title="errorRecordbleDialogDialogData.title"
      :showSubmitBtn="selectData.length > 0"
      @click-submit="submitErrorRecordbleDialog"
    />
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import {
  tabberHeight,
  searchParams,
  getTabberData,
  clearMemoryPageFn,
  setMemoryPageFn,
  replacePerCent
} from '@/utils'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'ProjectInfoManage',
  setup() {
    const router = useRouter()
    const { commit, getters, dispatch } = useStore()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const getMemoryPage = computed(() => getters.getMemoryPage)
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      searchName: getMemoryPage.value.searchData.searchName || '',
      companyId: getMemoryPage.value.searchData.companyId || '',
      projectStatus: getMemoryPage.value.searchData.projectStatus || 'Y'
    })
    const statusOptions = ref([
      { name: '全部', value: '' },
      { name: '有效', value: 'Y' },
      { name: '无效', value: 'N' },
    ])

    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      clearMemoryPageFn(commit, searchData)
      tabberData.page === 1 ? getProjectInfosData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getProjectInfosData() : (tabberData.page = 1)
    }

    // 单位名下拉列表
    const unitOptions = ref([])
    /* 结束 搜索 **********************************/

    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: getMemoryPage.value.page || 1,
      size: getMemoryPage.value.size || 10
    })

    // 获取列表
    const getProjectInfosData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData)
      searchDataParams.searchName = replacePerCent(searchData.searchName)
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetProjectInfos',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getProjectInfosData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }

    // 添加
    const clickPush = () => {
      clearMemoryPageFn(commit, searchData)
      router.push({ path: '/projectInfoManageAction' })
    }
    // 修改
    const clickUpdate = (index, row) => {
      setMemoryPageFn(commit, searchData, tabberData)
      router.push({ path: '/projectInfoManageAction', query: { edit: row.projectId } })
    }
    const clickMigration = (index, row) => {
      setMemoryPageFn(commit, searchData, tabberData)
      router.push({ path: '/projectInfoManageAction', query: { move: row.projectId } })
    }
    // 删除
    const deleteProjectInfoDialogRef = ref()
    const deleteProjectInfoData = reactive({
      content: '确定删除该项目信息？'
    })
    const clickDelete = (index, row) => {
      deleteProjectInfoData.projectId = row.projectId
      deleteProjectInfoDialogRef.value.showDialog = true
    }
    // 确定删除
    const submitDeleteProjectInfo = () => {
      const { projectId } = deleteProjectInfoData
      dispatch('fetchDeleteProjectInfo', projectId).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          tabberData.page === 1 ? getProjectInfosData() : (tabberData.page = 1)
          deleteProjectInfoDialogRef.value.showDialog = false
        }
      })
    }

    // 选中的添加到列表
    const errorRecordbleRef = ref()
    // const getSelection = selection => selection.map(item => item)
    // 选择指派人列表
    const selectData = ref([])
    // 选中单个
    const select = selection => {
      selectData.value = selection
    }
    // 选中全部
    const selectAll = selection => {
      selectData.value = selectData.value = selection
    }
    const errorRecordbleDialogDialogRef = ref()
    const errorRecordbleDialogDialogData = reactive({
      title: '批量导出',
      content: ''
    })
    // 弹出层
    const errorRecordbleDown = () => {
      if (selectData.value.length <= 0) {
        errorRecordbleDialogDialogData.content = '请选择项目'
      } else {
        errorRecordbleDialogDialogData.content = '确定导出选中的项目吗？'
      }
      errorRecordbleDialogDialogRef.value.showDialog = true
    }
    const jsonFields = reactive({
      合同名称: 'contractName',
      阿米吧: 'companyName',
      项目编号: 'projectCode',
      所属项目: 'projectName',
      项目类型: 'projectType',
      项目地址: 'address',
      建设单位: 'constructionUnit',
      设备数量: 'deviceCount',
      服务开始时间: 'serviceStartTime',
      服务结束时间: 'serviceEndTime',
      项目区域: 'area',
      详细地址: 'address',
      '运维费用（元）': 'opsExpenses',
      '专项费用（元）': 'specialExpenses',
      '累计付款（元）': 'paymentTotal',
      业主负责人: 'proprietor',
      负责人电话: 'leaderPhone',
      项目经理: 'projectManager',
      项目经理电话: 'managerPhone',
      主要服务人员: 'mainOpsUser',
      主要服务人员电话: 'servicePhone',
      服务人数: 'serviceCount',
      运维机构: 'serviceOrg',
      服务内容: 'serviceContent'
    })

    // 确定
    const { export_json_to_excel } = require('../../utils/excel/Export2Excel')
    const formatJson = (filterVal, jsonData) => jsonData.map(v => filterVal.map(j => v[j]))
    const submitErrorRecordbleDialog = () => {
      //导出的方法
      require.ensure([], () => {
        const tHeader = []
        // 上面设置Excel的表格第一行的标题
        const filterVal = []

        Object.keys(jsonFields).map(item => {
          tHeader.push(item)
          filterVal.push(jsonFields[item])
        })
        // 上面的index、nickName、name是tableData里对象的属性
        const list = selectData.value //把data里的tableData存到list
        const data = formatJson(filterVal, list)
        export_json_to_excel(tHeader, data, '列表excel')
        selectData.value = []
        errorRecordbleRef.value.clearSelection()
        errorRecordbleDialogDialogRef.value.showDialog = false
      })
    }

    const projectTotalStatisticsData = reactive({
      projectTotal: 0,
      total: 0
    })
    const getProjectTotalStatistics = () => {
      dispatch('fetchGetProjectTotalStatistics').then(data => {
        if (data && data.code === 200) {
          const { projectTotal, total } = data.data || {}
          projectTotalStatisticsData.projectTotal = projectTotal || 0
          projectTotalStatisticsData.total = total || 0
        }
      })
    }

    /* 结束 数据列表 **********************************/
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {
        noCompanyCode: 'business_department'
      })
      unitOptions.value = data || []
      getProjectInfosData()
      getProjectTotalStatistics()
    })
    return {
      projectTotalStatisticsData,
      jsonFields,
      errorRecordbleRef,
      selectData,
      select,
      selectAll,
      errorRecordbleDialogDialogRef,
      errorRecordbleDialogDialogData,
      errorRecordbleDown,
      submitErrorRecordbleDialog,
      unitOptions,
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      clickPush,
      clickUpdate,
      clickMigration,
      clickDelete,
      deleteProjectInfoData,
      deleteProjectInfoDialogRef,
      submitDeleteProjectInfo,
      isBusinessDepartment,
      statusOptions
    }
  }
}
</script>

<style lang="scss" scoped>
.project-info-manage {
  @extend %params-global;
}
</style>
