/**
 * 满意度登记接口
 */

const prefix = '/dseWeb'
export default [
  // 分页查询满意度登记记录
  { name: 'getSatisfactionRecords', url: `${prefix}/v1/op/satisfactionRecords`, method: 'get' },
  // 分页查询月度评价
  { name: 'getMonthEvaluates', url: `${prefix}/v1/op/monthEvaluates`, method: 'get' },
  // 新增月度评价
  { name: 'addMonthEvaluate', url: `${prefix}/v1/op/monthEvaluate`, method: 'post' },
  // 修改月度评价
  { name: 'updateMonthEvaluate', url: `${prefix}/v1/op/monthEvaluate`, method: 'put' },
  // 查询单个月度评价
  { name: 'getMonthEvaluate', url: `${prefix}/v1/op/monthEvaluate`, method: 'get' },
  // 分页查询工单信息
  { name: 'getOrderInfos', url: `${prefix}/v1/op/orderInfos`, method: 'get' },
  // 删除月度评价
  { name: 'monthEvaluateDelete', url: `${prefix}/v1/op/monthEvaluate`, method: 'delete' },
  // 月度评价导入
  { name: 'monthEvaluateImport', url: `${prefix}/v1/op/monthEvaluateImport`, method: 'post' }
]
