<template>
  <div class="site-location-manage">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="站点位置管理" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="100px" :model="searchData" ref="searchDataRef">
        <!-- 阿米巴 -->
        <ml-select
          prop="companyId"
          placeholder="请选择阿米巴"
          :options="unitOptions"
          label="阿米巴:"
          keyName="companyId"
          labelName="companyName"
          valueName="companyId"
          v-model="searchData.companyId"
          style="margin-bottom: 0"
        />
        <!-- 所属项目 -->
        <ml-select
          prop="projectId"
          placeholder="请选所属项目"
          :options="projectData"
          label="所属项目:"
          keyName="projectId"
          labelName="projectName"
          valueName="projectId"
          v-model="searchData.projectId"
          :style="{ marginBottom: 0 }"
          @change="searchFn"
        />
        <!-- 名称 -->
        <ml-input
          prop="siteAddr"
          placeholder="请输入站点站点位置"
          style="margin-bottom: 0"
          label="站点位置:"
          v-model="searchData.siteAddr"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
      </ml-form>
      <div class="add-btn-box">
        <el-button-group>
          <el-button icon="el-icon-plus" type="primary" @click="addHandle">新增</el-button>
          <el-button type="primary" @click="jumpHandle">坐标拾取</el-button>
        </el-button-group>
        <el-button-group>
          <el-button icon="el-icon-upload2" type="primary" @click="siteLocationExcelImport">
            批量导入
          </el-button>
          <el-button icon="el-icon-download" type="primary" @click="siteLocationExcelExport">
            批量导出
          </el-button>
        </el-button-group>
      </div>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(360)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="阿米巴"
          prop="companyName"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="所属项目"
          prop="projectName"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="站点位置(目的地)"
          prop="siteAddr"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="经度"
          prop="longitude"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="纬度"
          prop="latitude"
          min-width="15%"
        />
        <el-table-column align="center" label="操作" priop="createById" min-width="10%">
          <template #default="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              type="primary"
              @click="clickEdit(scope.$index, scope.row)"
            >
              修改
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="clickDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>
    <!-- 导入设备提示框 -->
    <ml-dialog width="600px" ref="importEquipmentDialogRef" title="批量导入" :showSubmitBtn="false">
      <template #body>
        <div class="import-equipment-dialog-box">
          <ml-form ref="uploadRef" labelWidth="100px" :model="uploadForm" :rules="uploadFormRules">
            <!-- 阿米巴 -->
            <ml-select
              prop="companyId"
              placeholder="请选择阿米巴"
              :options="unitOptions"
              label="阿米巴:"
              keyName="companyId"
              labelName="companyName"
              valueName="companyId"
              v-model="uploadForm.companyId"
              style="margin-bottom: 16px"
            />
            <!-- 所属项目 -->
            <ml-select
              prop="projectId"
              placeholder="请选所属项目"
              :options="projectOptionsData"
              label="所属项目:"
              keyName="projectId"
              labelName="projectName"
              valueName="projectId"
              v-model="uploadForm.projectId"
              :style="{ marginBottom: '16px', marginRight: '40px' }"
            />
            <el-form-item label="导入文件:" prop="file">
              <ml-upload
                ref="importEquipmentUploadRef"
                :showFileList="false"
                :autoUpload="false"
                @on-change="onChange"
                accept=".xls, .xlsx"
              >
                <el-button icon="el-icon-upload2" type="primary">批量导入</el-button>
              </ml-upload>
            </el-form-item>
          </ml-form>
          <div class="import-equipment-dialog">
            <span>请导入与站点信息表格匹配的文件还无模板？</span>
            <el-button type="text" @click="downloadModel">点击下载模板</el-button>
          </div>
        </div>
      </template>
    </ml-dialog>
    <!-- 删除提示框 -->
    <ml-dialog
      ref="deleteUserDialogRef"
      :content="deleteUserData.content"
      @click-submit="submitDeleteUser"
    />
    <site-dialog
      v-if="showDialog"
      ref="siteRef"
      :editData="editData"
      @close="closeHandle"
      @saveSuccess="saveSuccessHandle"
    ></site-dialog>
  </div>
</template>
<script>
import { computed, onMounted, reactive, ref, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import { FILEDOWNLOD, PCSSITEXPORT } from '@API'
import { exportPcsSiteequest, exportPcsSiteTemplateRequest } from '@/API/previewApi'
import siteDialog from './components/siteDialog.vue'
import {
  tabberHeight,
  searchParams,
  getTabberData,
  downloadFile,
  clearMemoryPageFn,
  replacePerCent
} from '@/utils'
export default {
  components: {
    siteDialog
  },
  setup() {
    const { commit, getters, dispatch } = useStore()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const getMemoryPage = computed(() => getters.getMemoryPage)
    const token = computed(() => getters.getAccessToken)
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      siteAddr: getMemoryPage.value.searchData.siteAddr || '',
      companyId: getMemoryPage.value.searchData.companyId || '',
      projectId: getMemoryPage.value.searchData.projectId || ''
    })
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: getMemoryPage.value.page || 1,
      size: getMemoryPage.value.size || 10
    })
    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      clearMemoryPageFn(commit, searchData)
      tabberData.page === 1 ? getSiteLocations() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getSiteLocations() : (tabberData.page = 1)
    }
    // 单位配置
    const unitOptions = ref([])
    // 所属项目配置
    const projectData = ref([])
    watch(
      () => searchData.companyId,
      newvalue => {
        if (newvalue) {
          getProjectData(newvalue)
        } else {
          projectData.value = []
        }
      }
    )
    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value && companyId) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }

    /* 开始 数据列表 **********************************/
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getSiteLocations()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }
    // 获取列表
    const getSiteLocations = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData)
      searchDataParams.siteAddr = replacePerCent(searchData.siteAddr)
      const { data } = await getTabberData(
        dispatch,
        'fetchGetPcsSitePage',
        params,
        searchDataParams
      )
      tabberData.data = data.records || []
      tabberData.total = data.total || 0
    }

    // 下载模板
    const downloadModel = () => {
      exportPcsSiteTemplateRequest(searchData)
        .then(res => {
          let fileName = '站点位置导入模版.xlsx'
          downloadFile(res.data, fileName)
        })
        .catch(error => {})
    }

    /* 导入 操作 **********************************/
    const uploadForm = reactive({
      companyId: '',
      projectId: ''
    })
    const uploadFormRules = reactive({
      companyId: [
        {
          required: true,
          message: '请选择阿米巴',
          trigger: 'blur'
        }
      ],
      projectId: [
        {
          required: true,
          message: '请选择项目',
          trigger: 'blur'
        }
      ]
    })
    const projectOptionsData = ref([])

    watch(
      () => uploadForm.companyId,
      newvalue => {
        uploadForm.projectId = ''
        if (newvalue) {
          getProjectOptionsData(newvalue)
        } else {
          projectOptionsData.value = []
        }
      }
    )
    // 处理所属项目
    const getProjectOptionsData = async companyId => {
      const projectParams = {}
      if (isBusinessDepartment.value && companyId) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectOptionsData.value = data || []
    }

    const uploadRef = ref(null)
    // 导入
    const importEquipmentDialogRef = ref()
    const importEquipmentUploadRef = ref()
    const siteLocationExcelImport = () => {
      uploadForm.companyId = ''
      uploadForm.projectId = ''
      importEquipmentDialogRef.value.showDialog = true
    }
    const onChange = (ev, file) => {
      if (file && file.length > 0) {
        const ImportFormData = new FormData()
        ImportFormData.append('file', file[0].raw)
        ImportFormData.append('companyId', uploadForm.companyId)
        ImportFormData.append('projectId', uploadForm.projectId)
        importEquipmentUploadRef.value.CustomUploadRef.uploadFiles = []
        uploadRef.value.CustomFormRef.validate().then(() => {
          dispatch('fetchSiteImportPcsSite', ImportFormData).then(data => {
            if (data && data.code === 200) {
              tabberData.page === 1 ? getSiteLocations() : (tabberData.page = 1)
              importEquipmentDialogRef.value.showDialog = false
            }
            commit('setError', {
              status: true,
              title: data.message,
              message: '正在获取新的数据...',
              type: 'success'
            })
          })
        })
      }
    }

    // 导出
    const siteLocationExcelExport = () => {
      exportPcsSiteequest(searchData)
        .then(res => {
          let fileName = '站点位置.xlsx'
          downloadFile(res.data, fileName)
        })
        .catch(error => {})
    }

    // 删除
    const deleteUserDialogRef = ref()
    const deleteUserData = reactive({
      content: '确定删除该站点吗？',
      id: ''
    })

    const clickDelete = (index, row) => {
      deleteUserData.id = row.id
      deleteUserDialogRef.value.showDialog = true
    }
    // 确定删除
    const submitDeleteUser = () => {
      const { id } = deleteUserData
      dispatch('fetchDeletePcsSite', { id: id }).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          tabberData.page === 1 ? getSiteLocations() : (tabberData.page = 1)
          deleteUserDialogRef.value.showDialog = false
        }
      })
    }
    /* 开始 新增/修改 **********************************/
    const showDialog = ref(false)
    const siteRef = ref(null)
    const editData = ref({})
    const addHandle = async () => {
      showDialog.value = true
      editData.value = {}
      await nextTick()
      siteRef.value.dialogRef.showDialog = true
    }
    const closeHandle = () => {
      showDialog.value = false
    }
    const saveSuccessHandle = () => {
      searchFn()
    }
    const clickEdit = async (index, row) => {
      showDialog.value = true
      editData.value = row
      await nextTick()
      siteRef.value.dialogRef.showDialog = true
    }

    const jumpHandle = () => {
      window.open('https://lbs.amap.com/tools/picker', '_blank')
    }

    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {
        noCompanyCode: 'business_department'
      })
      unitOptions.value = data || []
      getSiteLocations()
    })
    return {
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      getSiteLocations,
      tabberData,
      tabberHeight,
      currentChange,
      sizeChange,
      unitOptions,
      getProjectData,
      projectData,
      downloadModel,
      importEquipmentDialogRef,
      importEquipmentUploadRef,
      siteLocationExcelImport,
      siteLocationExcelExport,
      onChange,
      clickDelete,
      submitDeleteUser,
      deleteUserDialogRef,
      deleteUserData,
      showDialog,
      addHandle,
      siteRef,
      closeHandle,
      saveSuccessHandle,
      clickEdit,
      editData,
      jumpHandle,
      uploadForm,
      uploadFormRules,
      uploadRef,
      projectOptionsData
    }
  }
}
</script>
<style lang="scss" scoped>
.site-location-manage {
  .add-btn-box {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}

.import-equipment-dialog-box {
  width: 100%;
  padding: 0 20px;
  .import-equipment-dialog {
    width: 100%;
    display: flex;
    // justify-content: start;
    align-items: center;
    span {
      margin-right: 20px;
    }
    &:first-child {
      margin-bottom: 20px;
    }
  }
}
</style>
