/**
 * 满意度登记接口实现方法
 */

// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    // 分页查询满意度登记记录
    async fetchGetSatisfactionRecords({ commit, getters }, params) {
      const { getSatisfactionRecords } = getters.getHttps
      const { data } = await getSatisfactionRecords(params)
      return setStoreApiDate(data, commit)
    },
    // 分页查询满意度登记记录
    async fetchGetMonthEvaluates({ commit, getters }, params) {
      const { getMonthEvaluates } = getters.getHttps
      const { data } = await getMonthEvaluates(params)
      return setStoreApiDate(data, commit)
    },
    // 新增月度评价
    async fetchAddMonthEvaluate({ commit, getters }, params) {
      const { addMonthEvaluate } = getters.getHttps
      const { data } = await addMonthEvaluate(params)
      return setStoreApiDate(data, commit)
    },
    // 修改月度评价
    async fetchUpdateMonthEvaluate({ commit, getters }, params) {
      const { updateMonthEvaluate } = getters.getHttps
      const { data } = await updateMonthEvaluate(params)
      return setStoreApiDate(data, commit)
    },
    // 查询单个月度评价
    async fetchGetMonthEvaluate({ commit, getters }, evaluateId) {
      const { getMonthEvaluate } = getters.getHttps
      const { data } = await getMonthEvaluate(false, false, {
        url: `${prefix}/v1/op/monthEvaluate/${evaluateId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 分页查询工单信息
    async fetchGetOrderInfos({ commit, getters }, params) {
      const { getOrderInfos } = getters.getHttps
      const { data } = await getOrderInfos(params)
      return setStoreApiDate(data, commit)
    },
    // 删除月度评价
    async fetchmonthEvaluateDelete({ commit, getters }, evaluateId) {
      const { monthEvaluateDelete } = getters.getHttps
      const { data } = await monthEvaluateDelete(false, false, {
        url: `${prefix}/v1/op/monthEvaluate/${evaluateId}`
      })
      return setStoreApiDate(data, commit)
    },
    // 分页查询满意度登记记录
    async fetchMonthEvaluateImport({ commit, getters }, params) {
      const { monthEvaluateImport } = getters.getHttps
      const { data } = await monthEvaluateImport(params)
      return setStoreApiDate(data, commit)
    },
  }
}
