<template>
  <div class="inspect-tasks-details">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="巡检详情" />
    <ml-tip class="scroll-box" :showHeader="false" style="border: 0">
      <el-scrollbar :height="tabberHeight(185)">
        <div ref="pdfBoxRef" class="content-box">
          <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane label="巡检详情" name="detail"></el-tab-pane>
            <el-tab-pane label="流程进度" name="process"></el-tab-pane>
            <el-tab-pane label="里程信息" name="pec"></el-tab-pane>
          </el-tabs>
          <div v-if="activeName === 'detail'">
            <!-- 表单 -->
            <ml-form disabled labelWidth="200px" style="width: 1200px; margin: 20px auto 40px">
              <!-- 提示标题 -->
              <el-divider content-position="left" style="font-weight: bold"
                >巡检详情信息</el-divider
              >
              <div class="globalTitle inspect-tasks-details-globalTitle">
                巡检详情
                <el-image
                  class="inspect-tasks-details-globalTitle-image"
                  v-if="inspectOrderStatusImg"
                  :src="inspectOrderStatusImg"
                />
              </div>
              <el-row>
                <el-col :span="12">
                  <!-- 所属项目 -->
                  <ml-input
                    prop="projectName"
                    label="所属项目:"
                    placeholder="请输入所属项目"
                    v-model.trim="InspectTasksDetailsForm.projectName"
                  />
                </el-col>
                <el-col :span="12">
                  <!-- 巡检计划 -->
                  <ml-input
                    prop="planName"
                    label="巡检计划:"
                    placeholder="请输入巡检计划"
                    v-model.trim="InspectTasksDetailsForm.planName"
                  />
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <!-- 计划业务类型 -->
                  <ml-input
                    prop="planBusinessTypeName"
                    label="计划业务类型:"
                    placeholder="请输入计划业务类型"
                    v-model.trim="InspectTasksDetailsForm.planBusinessTypeName"
                  />
                </el-col>
                <el-col :span="12" v-if="InspectTasksDetailsForm.planBusinessType === '1'">
                  <ml-input
                    prop="qualityControlType"
                    label="业务类型:"
                    placeholder="请输入业务类型"
                    v-model.trim="InspectTasksDetailsForm.qualityControlType"
                  />
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <!-- 巡检类型 -->
                  <ml-input
                    prop="planType"
                    label="巡检类型:"
                    placeholder="请输入巡检类型"
                    v-model.trim="InspectTasksDetailsForm.planType"
                  />
                </el-col>
                <el-col :span="12">
                  <ml-input
                    prop="taskName"
                    label="巡检名称:"
                    placeholder="请输入巡检名称"
                    v-model.trim="InspectTasksDetailsForm.taskName"
                  />
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <ml-input
                    prop="startTime"
                    label="预计开始时间:"
                    placeholder="请输入预计开始时间"
                    v-model.trim="InspectTasksDetailsForm.startTime"
                  />
                </el-col>
                <el-col :span="12">
                  <ml-input
                    prop="endTime"
                    label="预计结束时间:"
                    placeholder="请输入预计结束时间"
                    v-model.trim="InspectTasksDetailsForm.endTime"
                  />
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <ml-input
                    prop="actualStartTime"
                    label="实际开始时间:"
                    placeholder=""
                    v-model.trim="InspectTasksDetailsForm.actualStartTime"
                  />
                </el-col>
                <el-col :span="12">
                  <ml-input
                    prop="endTime"
                    label="实际结束时间:"
                    placeholder=""
                    v-model.trim="InspectTasksDetailsForm.completeTime"
                  />
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <ml-input
                    prop="managerUserName"
                    label="负责人:"
                    placeholder="请输入负责人"
                    v-model.trim="InspectTasksDetailsForm.managerUserName"
                  />
                </el-col>
                <el-col :span="12">
                  <ml-input
                    prop="userName"
                    label="执行人:"
                    placeholder="请输入执行人"
                    v-model.trim="InspectTasksDetailsForm.userName"
                  />
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <!-- 流程 -->
                  <ml-select
                    prop="process.definitionId"
                    placeholder="请选择流程"
                    :options="processData"
                    label="流程:"
                    keyName="definitionId"
                    labelName="processName"
                    valueName="definitionId"
                    v-model="InspectTasksDetailsForm.process.definitionId"
                    :filterable="true"
                    @change="definitionChange"
                  />
                </el-col>
              </el-row>
              <ml-input
                prop="content"
                label="巡检内容:"
                placeholder="请输入巡检内容"
                type="textarea"
                :rows="5"
                v-model.trim="InspectTasksDetailsForm.content"
              />
            </ml-form>

            <ml-form
              labelWidth="200px"
              style="width: 1200px; margin: 20px auto 40px"
              :model="InspectTasksDetailsForm"
            >
              <div class="globalTitle" v-if="InspectTasksDetailsForm.attachVos.length > 0">
                附件
              </div>
              <el-form-item label="附件:" v-if="InspectTasksDetailsForm.attachVos.length > 0">
                <ml-list
                  fileName="fileName"
                  :showDelete="false"
                  :showDown="true"
                  :list="InspectTasksDetailsForm.attachVos"
                />
              </el-form-item>
            </ml-form>
          </div>
          <div v-else-if="activeName === 'process'">
            <div class="globalTitle" v-if="deviceErrorLogsByError.length > 0">流程进度</div>
            <el-form-item v-if="deviceErrorLogsByError.length > 0" style="margin-bottom: 60px">
              <process-schedule-new
                disabled
                v-for="(item, index) in deviceErrorLogsByError"
                :key="item"
                :item="item"
                :state="index === 0"
                @view-form="viewFromHandle"
              />
            </el-form-item>
          </div>
          <div v-else-if="activeName === 'pec'">
            <div class="globalTitle" v-if="deviceErrorLogsByError.length > 0">里程信息</div>
            <el-table :data="pecList" style="width: 100%">
              <el-table-column align="center" label="车牌号" prop="carNumber" min-width="15%" />
              <el-table-column align="center" label="出发地址" prop="startAddr" min-width="15%" />
              <el-table-column align="center" label="出发地图片" prop="companyName" min-width="15%">
                <template #default="scope">
                  <el-image
                    style="width: 60px; height: 60px"
                    :src="`${BASEURL}${FILEPREVIEWURL}/${scope.row.startFileList[0].filePath}`"
                    hide-on-click-modal
                    v-if="scope.row.startFileList[0]"
                    :preview-src-list="[
                      `${BASEURL}${FILEPREVIEWURL}/${scope.row.startFileList[0].filePath}`
                    ]"
                  />
                </template>
              </el-table-column>
              <el-table-column align="center" label="出发时间" prop="createTime" min-width="15%" />
              <el-table-column
                align="center"
                label="目的地地址"
                prop="endAddress"
                min-width="15%"
              />
              <el-table-column align="center" label="目的地图片" prop="companyName" min-width="15%">
                <template #default="scope">
                  <el-image
                    style="width: 60px; height: 60px"
                    :src="`${BASEURL}${FILEPREVIEWURL}/${scope.row.endFileList[0].filePath}`"
                    hide-on-click-modal
                    v-if="scope.row.endFileList[0]"
                    :preview-src-list="[
                      `${BASEURL}${FILEPREVIEWURL}/${scope.row.endFileList[0].filePath}`
                    ]"
                  />
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="到达时间"
                prop="endCreateTime"
                min-width="15%"
              />
              <el-table-column align="center" label="乘车人员" prop="endCreateTime" min-width="15%">
                <template #default="scope">
                  <span
                    v-for="item in scope.row.byCarUserVos"
                    :key="item.userId"
                    style="margin-right: 8px"
                    >{{ item.userName }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column align="center" label="状态" prop="companyName" min-width="15%">
                <template #default="scope">
                  <span>{{ scope.row.recordType === 'START' ? '进行中' : '已完成' }}</span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="里程数(km)"
                prop="actualMileage"
                min-width="15%"
              ></el-table-column>
            </el-table>
          </div>
        </div>
      </el-scrollbar>
    </ml-tip>
    <!-- 处理故障按钮组 -->
    <div class="btn-box">
      <el-button
        v-for="item in customButtons"
        :key="item.value"
        size="medium"
        type="primary"
        @click="clickReportOrder(item)"
      >
        {{ item.name }}
      </el-button>
      <el-button size="medium" icon="el-icon-circle-close" @click="clickCancelFultServiceCenter">
        返回上一页
      </el-button>
      <el-button size="medium" icon="el-icon-download" type="primary" @click="clickDownloadExcel">
        导出
      </el-button>
    </div>
    <!-- 办结提示框 -->
    <!-- 办结 -->
    <completed-dialog
      v-if="showCompletedDialog"
      ref="completedRef"
      :conf="processCof"
      :btnCode="btnCode"
      :btnName="btnName"
      :type="completedType"
      :sourData="sourData"
      @close="closeCompletedDialog"
      @success="successCompletedDialog"
    ></completed-dialog>
    <!-- 提交报告 -->
    <submit-task-dialog
      v-if="showSubmitTaskDialog"
      ref="submitTaskRef"
      :conf="processCof"
      :btnCode="btnCode"
      :btnName="btnName"
      :type="submitTaskType"
      :sourData="sourData"
      :inspectTaskId="inspectTaskId"
      @close="closeSubmitTaskDialog"
      @success="successSubmitTaskDialog"
    ></submit-task-dialog>
  </div>
</template>

<script>
import { computed, nextTick, onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { tabberHeight, searchParams, formetData } from '@/utils'
import ProcessScheduleNew from '@components/processScheduleNew.vue'
import submitTaskDialog from '@/components/processDialog/submitTaskDialog'
import completedDialog from '@/components/processDialog/completedDialog'

import html2canvas from 'html2canvas'
import jsPdf from 'jspdf'
// 巡检工单状态（-1 审核退回 0待处理 1待审核 2 已办结）
// 待审核
import isAuditImg from '../../assets/image/is-audit.png'
// 待处理
import isDealImg from '../../assets/image/is-deal.png'
// 已办结
import completeImg from '../../assets/image/complete.png'
import { useProcessHandler } from '@/utils/useProcessHandler'

import { BASEURL, FILEPREVIEWURL } from '@API'

export default {
  name: 'InspectTasksDetails',
  components: {
    ProcessScheduleNew,
    submitTaskDialog,
    completedDialog
  },
  setup() {
    const { dispatch, getters, commit } = useStore()
    const router = useRouter()
    const route = useRoute()
    const { inspectTaskId } = route.query
    const userInfo = computed(() => getters.getUser)

    /* 开始 表单 **********************************/

    // 表单配置
    const InspectTasksDetailsForm = reactive({
      companyId: '',
      inspectTaskId: '',
      projectName: '',
      projectId: '',
      planName: '',
      planType: '',
      taskName: '',
      startTime: '',
      endTime: '',
      managerUserName: '',
      userName: '',
      content: '',
      inspectOrderStatus: '',
      attachVos: [],
      actualStartTime: '',
      completeTime: '',
      inspectUserVos: [],
      qualityControlType: '',
      planBusinessType: '',
      planBusinessTypeName: '',
      process: {
        businessId: '',
        processKey: '',
        definitionId: '',
        procInsId: '',
        businessType: '',
        processName: ''
      }
    })
    const inspectOrderStatusImg = ref()
    watch(
      () => InspectTasksDetailsForm.inspectOrderStatus,
      newvalue => {
        if (newvalue) {
          inspectOrderStatusImg.value =
            newvalue === '2' ? completeImg : newvalue === '1' ? isAuditImg : isDealImg
        }
      }
    )

    const isAudit = (arr = []) => {
      const { userId } = userInfo.value
      const userIdArr = arr ? arr.map(item => item.userId) : []
      return userIdArr.includes(userId)
    }

    const isAmibaManager = row => {
      const { userType, companyId } = userInfo.value
      return userType === 'amiba_manager' && companyId === row.companyId
    }

    // 取消表单
    const clickCancelFultServiceCenter = () => {
      router.go(-1)
      // router.push({ path: '/faultServiceCenter' })
    }
    // 获取单个故障信息
    const getFaultServiceCenterDetails = async () => {
      // 查看故障详情
      await dispatch('fetchGetInspectTask', inspectTaskId).then(data => {
        if (data && data.code === 200 && data.data) {
          Object.keys(InspectTasksDetailsForm).map(item => {
            if (['attachVos'].includes(item)) {
              InspectTasksDetailsForm[item] = data.data[item] || []
            } else {
              InspectTasksDetailsForm[item] = data.data[item]
            }
          })
          InspectTasksDetailsForm['planBusinessTypeName'] =
            InspectTasksDetailsForm.planBusinessType === '1' ? '质检' : '巡检'
        }
      })
      // // 查询故障流程进度
      // await dispatch('fetchGetInspectLogsByTask', { inspectTaskId }).then(data => {
      //   if (data && data.code === 200) {
      //     deviceErrorLogsByError.value = data.data
      //   }
      // })
    }

    // 导出 Excel
    const pdfBoxRef = ref()
    const clickDownloadExcel = () => {
      nextTick(() => {
        const w = pdfBoxRef.value.offsetWidth
        const h = pdfBoxRef.value.offsetHeight + 60
        html2canvas(pdfBoxRef.value, {
          useCORS: true,
          y: -100,
          x: (w - 1660) / 2,
          width: 1600,
          height: h
        }).then(canvas => {
          const contentW = canvas.width
          const contentH = canvas.height
          // 每页的高度
          const pageH = (contentW / 592.25) * 841.89
          // 款生成PDF的html高度
          let leftH = contentH
          // 页面偏移
          let position = 0
          // A4 尺寸
          const imgW = 595.28
          const imgH = (592.28 / contentW) * contentH
          //返回图片URL，参数：图片格式和清晰度(0-1)
          const pageData = canvas.toDataURL('image/png', 1.0)
          const pdf = new jsPdf('', 'pt', 'a4', true)

          // //方向默认竖直，尺寸ponits，格式a4【595.28,841.89]
          setTimeout(() => {
            // //需要dataUrl格式
            if (leftH < pageH) {
              pdf.addImage(pageData, 'png', 0, 0, imgW, imgH)
            } else {
              while (leftH > 0) {
                pdf.addImage(pageData, 'png', 0, position, imgW, imgH)
                leftH -= pageH
                position -= 841.89
                // 避免添加空白页面
                if (leftH > 0) {
                  pdf.addImage()
                }
              }
            }
            pdf.save(`${InspectTasksDetailsForm.taskName || '巡检详情'}.pdf`)
          }, 600)
        })
      })
    }

    /* 结束 用户表单 **********************************/

    /** 办结 开始***********************************/
    const clickSubmitFultServiceCenter = () => {}
    const updateInfoHandle = async () => {
      // 获取单个故障信息
      await getFaultServiceCenterDetails()
      await getProcessConf(inspectTaskId)
      await getDeviceErrorLogsByError(inspectTaskId)
    }

    /** 流程处理 开始 2024-11-12 ***********************************/
    const {
      btnCode,
      btnName,
      processCof,
      customButtons,
      formDisabled,
      deviceErrorLogsByError,
      showDistributeDialog,
      distributeRef,
      completedType,
      showCompletedDialog,
      completedRef,
      sourData,
      showSubmitDialog,
      submitRef,
      submitType,
      getProcessConf,
      getDeviceErrorLogsByError,
      clickReportOrder,
      successDistributedDialog,
      closeDistributedDialog,
      successCompletedDialog,
      closeCompletedDialog,
      successSubmitDialog,
      closeSubmitDialog,
      viewFromHandle,
      showSubmitTaskDialog,
      submitTaskRef,
      submitTaskType,
      successSubmitTaskDialog,
      closeSubmitTaskDialog
    } = useProcessHandler(dispatch, updateInfoHandle, clickSubmitFultServiceCenter)
    /** 流程处理 结束***********************************/

    const processData = ref([])
    watch(
      () => InspectTasksDetailsForm.projectId,
      newValue => {
        processData.value = []
        if (newValue) {
          getProcessData()
        } else {
          InspectTasksDetailsForm.process.definitionId = ''
        }
      }
    )
    const getProcessData = () => {
      let params = {
        companyId: '',
        projectId: InspectTasksDetailsForm.projectId,
        businessType: 'inspect_task',
        pageIndex: 1,
        pageSize: 500
      }
      dispatch('fetchProjectProcessPage', params).then(res => {
        if (res.code === 200) {
          processData.value = res.data.records
          const item = processData.value.find(
            d => d.definitionId === InspectTasksDetailsForm.process.definitionId
          )
          if (!item) {
            let processDataValue = processData.value[0] || {}
            InspectTasksDetailsForm.process.definitionId = processDataValue.definitionId || ''
            InspectTasksDetailsForm.process.processKey = processDataValue.processKey || ''
            InspectTasksDetailsForm.process.businessType = processDataValue.businessType || ''
            InspectTasksDetailsForm.process.processName = processDataValue.processName || ''
          }
        }
      })
    }
    const definitionChange = value => {
      if (value) {
        const item = processData.value.find(d => d.definitionId === value)
        InspectTasksDetailsForm.process.processKey = (item && item.processKey) || ''
        InspectTasksDetailsForm.process.businessType = (item && item.businessType) || ''
        InspectTasksDetailsForm.process.processName = (item && item.processName) || ''
      } else {
        InspectTasksDetailsForm.process.processKey = ''
        InspectTasksDetailsForm.process.businessType = ''
        InspectTasksDetailsForm.process.processName = ''
      }
    }

    const activeName = ref('detail')
    let pecList = ref([])
    const getPecListHandle = () => {
      dispatch('fetchListByErrorIdPcs', { errorId: inspectTaskId }).then(res => {
        if (res.code === 200) {
          pecList.value = res.data
          let recordList = res.data
          pecList.value = recordList.map(d => {
            const endItem = d.recordList.find(k => k.recordType === 'END')
            const startItem = d.recordList.find(k => k.recordType === 'START')
            if (endItem) {
              d.recordType = 'END'
              d.endAddress = endItem.addr
              d.actualMileage = endItem.actualMileage
              d.endCreateTime = endItem.createTime
            } else {
              d.recordType = 'START'
            }
            d.createTime = (startItem && startItem.createTime) || ''
            d.startFileList = (startItem && startItem.fileList) || []
            d.endFileList = (endItem && endItem.fileList) || []
            return d
          })
        }
      })
    }


    onMounted(async () => {
      await updateInfoHandle()
      await getPecListHandle()
    })
    return {
      pdfBoxRef,
      clickDownloadExcel,
      InspectTasksDetailsForm,
      clickCancelFultServiceCenter,
      tabberHeight,
      inspectOrderStatusImg,
      isAudit,
      isAmibaManager,
      btnCode,
      btnName,
      processCof,
      customButtons,
      formDisabled,
      showDistributeDialog,
      deviceErrorLogsByError,
      distributeRef,
      completedType,
      showCompletedDialog,
      completedRef,
      sourData,
      showSubmitDialog,
      submitRef,
      submitType,
      getProcessConf,
      getDeviceErrorLogsByError,
      clickReportOrder,
      successDistributedDialog,
      closeDistributedDialog,
      successCompletedDialog,
      closeCompletedDialog,
      successSubmitDialog,
      closeSubmitDialog,
      viewFromHandle,
      showSubmitTaskDialog,
      submitTaskRef,
      submitTaskType,
      inspectTaskId,
      successSubmitTaskDialog,
      closeSubmitTaskDialog,
      processData,
      definitionChange,
      getProcessData,
      activeName,
      getPecListHandle,
      pecList,
      FILEPREVIEWURL,
      BASEURL
    }
  }
}
</script>

<style lang="scss">
.inspect-tasks-details {
  position: relative;
  &-globalTitle {
    position: relative;
    z-index: 1;
    &-image {
      position: absolute;
      width: 100px;
      top: -50px;
      left: 150px;
    }
  }
}
.btn-box {
  display: flex;
  align-items: center;
  padding-left: 450px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 60px;
  background: #fff;
  border-top: 1px dashed #ebeef5;
}
.content-box {
  padding-bottom: 60px;
  box-sizing: border-box;
}
</style>
