<template>
  <task-detail typeTitle="多点线性核查"></task-detail>
</template>

<script>
import taskDetail from '../components/taskDetail.vue';
export default {
  components: {
    taskDetail
  },
}
</script>
