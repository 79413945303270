<template>
  <ml-dialog
    width="600px"
    ref="dialogRef"
    :title="title"
    :showSubmitBtn="type === 'edit'"
    :btnLoading="btnLoading"
    @click-submit="submitHandle"
    @click-close="closeHandle"
  >
    <template #body>
      <div class="form-box custom-form-box">
        <v-form-render
          v-if="showForm"
          :form-json="formJson"
          :form-data="formData"
          :option-data="optionData"
          ref="vFormRef"
          :disabled="type === 'view'"
        >
        </v-form-render>

        <ml-form
          labelWidth="100px"
          style="width: 100%"
          :model="dialogData"
          ref="bindDialogFormRef"
          :rules="dialogFormRule"
          v-if="type === 'edit'"
        >
          <ml-input
            prop="comment"
            placeholder="请输入审批意见"
            label="审批意见"
            type="textarea"
            :rows="5"
            v-model="dialogData.comment"
          />
          <el-form-item label="上传附件:">
            <ml-upload
              multiple
              autoUpload
              :showFileList="false"
              :action="action"
              :data="{ linkType: 'error' }"
              :headers="{ Authorization: toKen }"
              name="files"
              @on-success="onSuccess"
            >
              <el-button size="medium" icon="el-icon-circle-plus-outline" type="primary">
                上传附件
              </el-button>
            </ml-upload>
          </el-form-item>
          <el-form-item>
            <ml-list fileName="fileName" :list="dialogData.fileList" />
          </el-form-item>
        </ml-form>
      </div>
    </template>
  </ml-dialog>
</template>
  
  <script>
import { computed, nextTick, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { BASEURL, FILEUPLOADURL } from '@API'
export default {
  name: 'distributeDialog',
  props: {
    conf: {
      type: Object,
      default() {
        return {}
      }
    },
    btnCode: {
      type: String,
      default: ''
    },
    btnName: {
      type: String,
      default: ''
    },
    sourData: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      default: 'edit'
    }
  },
  emits: ['success', 'close'],
  setup(props, context) {
    const { commit, dispatch, getters } = useStore()
    const toKen = computed(() => getters.getToken)
    const dialogRef = ref()
    const bindDialogFormRef = ref()
    const dialogFormRule = {
      comment: [{ required: true, message: '请输入描述', trigger: 'blur' }]
    }
    const title = ref(props.btnName)
    watch(
      () => props.btnCode,
      () => {}
    )

    const dialogData = reactive({
      buttonCode: props.btnCode,
      procInsId: props.conf && props.conf.taskDto && props.conf.taskDto.processInstanceId,
      taskId: props.conf && props.conf.taskDto && props.conf.taskDto.id,
      variables: props.conf && props.conf.variables,
      comment: '',
      toUserId: '',
      customParamJson: '',
      fileList: [],
      nodeId: props.conf && props.conf.id,
      businessParamJson: '',
      created: props.conf && props.conf.taskDto && props.conf.taskDto.created,
      formConf: props.conf.formConf
    })
    let btnLoading = ref(false)

    // 上传附件
    const action = ref(`${BASEURL}${FILEUPLOADURL}`)
    const onSuccess = files => {
      if (files.data) {
        dialogData.fileList = [...dialogData.fileList, ...files.data]
      }
    }

    // 自定义表单
    let formJson = reactive({})
    const formData = reactive({})
    const optionData = reactive({})
    const vFormRef = ref(null)
    const showForm = ref(false)

    const submitHandle = () => {
      if (showForm.value) {
        vFormRef.value.getFormData().then(data => {
          dialogData.customParamJson = JSON.stringify(data)
          vFormRef.value.validateForm(valid => {
            if (valid) {
              submitRequestHandle()
            }
          })
        })
      } else {
        submitRequestHandle()
      }
    }
    const submitRequestHandle = () => {
      bindDialogFormRef.value.CustomFormRef.validate().then(() => {
        btnLoading.value = true
        dispatch('fetchButtonClickRequest', dialogData).then(res => {
          btnLoading.value = false
          if (res && res.code === 200) {
            commit('setError', {
              status: true,
              title: res.message,
              message: '处理成功...',
              type: 'success'
            })
            context.emit('success')
          }
        })
      })
    }
    const closeHandle = () => {
      context.emit('close')
    }
    onMounted(async () => {
      title.value = props.btnName
      if (props.btnCode.indexOf('act_submit_type_approve') > -1) {
        if (props.conf.formConf) {
          showForm.value = true
          setTimeout(() => {
            formJson.value = props.conf.formConf
            vFormRef.value.setFormJson(formJson.value)
            if (props.type === 'view') {
              vFormRef.value.setFormData(props.sourData)
              vFormRef.value.disableForm()
            }
          }, 300)
        }
      }
    })
    return {
      dialogRef,
      dialogFormRule,
      title,
      dialogData,
      submitHandle,
      closeHandle,
      btnLoading,
      toKen,
      action,
      onSuccess,
      formJson,
      formData,
      optionData,
      vFormRef,
      showForm,
      submitRequestHandle,
      bindDialogFormRef
    }
  }
}
</script>

<style lang="scss" scoped>
.form-box {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
.custom-form-box {
  /deep/ .el-form-item__label {
    width: 100px !important;
    white-space: pre-wrap;
  }
}
</style>