<template>
  <div class="inspectPlan-action">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" :content="content" />
    <ml-tip class="scroll-box" :showHeader="false" style="border: 0">
      <el-scrollbar :height="tabberHeight(185)">
        <!-- 账户表单 -->
        <ml-form
          ref="inspectPlanFormRef"
          labelWidth="200px"
          style="width: 1200px; margin: 40px auto"
          :model="inspectPlanForm"
          :rules="inspectPlanFormRules"
          :disabled="!!viewId"
        >
          <el-row>
            <el-col :span="12">
              <!-- 单位 -->
              <ml-select
                prop="companyId"
                placeholder="请选择单位"
                :options="unitOptions"
                label="单位:"
                keyName="companyId"
                labelName="companyName"
                valueName="companyId"
                v-model="inspectPlanForm.companyId"
                v-model:node="inspectPlanFormNode"
                @change="selectUnit"
              />
            </el-col>
            <el-col :span="12">
              <!-- 所属项目 -->
              <ml-select
                prop="projectId"
                placeholder="请选择所属项目"
                :options="projectData"
                label="所属项目:"
                keyName="projectId"
                labelName="projectName"
                valueName="projectId"
                v-model="inspectPlanForm.projectId"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 输入框-水样对比计划名称 -->
              <ml-input
                prop="planName"
                label="水样对比计划名称:"
                placeholder="请输入水样对比计划名称"
                v-model.trim="inspectPlanForm.planName"
              />
            </el-col>
            <el-col :span="12">
              <!-- 计划业务类型 -->
              <ml-select
                prop="planBusinessType"
                placeholder="请选择计划业务类型"
                :options="planBusinessData"
                label="计划业务类型:"
                keyName="value"
                labelName="label"
                valueName="value"
                disabled
                v-model="inspectPlanForm.planBusinessType"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12" v-if="inspectPlanForm.planBusinessType === '1'">
              <!-- 业务类型 -->
              <ml-select
                prop="qualityControlType"
                placeholder="请选择业务类型"
                :options="qualityTypeData"
                label="业务类型:"
                keyName="label"
                labelName="label"
                valueName="label"
                v-model="inspectPlanForm.qualityControlType"
                disabled
              />
            </el-col>
            <el-col :span="12">
              <!-- 水样对比类型 -->
              <ml-select
                prop="planType"
                placeholder="请选择水样对比类型"
                :options="inspectionTypeData"
                label="水样对比类型:"
                keyName="label"
                labelName="label"
                valueName="label"
                v-model="inspectPlanForm.planType"
                @change="changePlanTypeFn"
                disabled
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 日期 -->
              <ml-date-picker
                prop="date"
                startPlaceholder="开始日期"
                endPlaceholder="结束日期"
                label="日期:"
                :type="daterangeType"
                v-model="inspectPlanForm.date"
                @change="inspectPlanForm.taskTime = ''"
              />
            </el-col>
            <el-col :span="12">
              <!-- 日期 -->
              <ml-date-picker
                prop="taskTime"
                placeholder="请选择生成任务时间"
                label="生成任务时间"
                type="datetime"
                v-model="inspectPlanForm.taskTime"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="20">
              <el-form-item label="执行人:" prop="inspectUserVos">
                <div class="name-tag-box">
                  <el-tag
                    size="mini"
                    type="info"
                    v-for="(item, index) in inspectPlanForm.inspectUserVos"
                    :key="item"
                    @close="clickTagCloseI(index)"
                    >{{ item.userName }}</el-tag
                  >
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <div style="padding: 0 10px">
                <el-button
                  :disabled="!inspectPlanForm.companyId"
                  type="text"
                  @click="clickInspectUserVosAdd"
                  >添加</el-button
                >
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <!-- 流程 -->
              <ml-select
                prop="process.definitionId"
                placeholder="请选择流程"
                :options="processData"
                label="流程:"
                keyName="definitionId"
                labelName="processName"
                valueName="definitionId"
                v-model="inspectPlanForm.process.definitionId"
                :filterable="true"
                @change="definitionChange"
              />
            </el-col>
          </el-row>
          <!-- 输入框-标样核查内容 -->
          <ml-input
            prop="content"
            label="水样对比内容:"
            type="textarea"
            :rows="5"
            placeholder="请输入水样对比内容"
            :maxlength="200"
            show-word-limit
            v-model.trim="inspectPlanForm.content"
          />
          <el-row>
            <el-col :span="10">
              <ml-input
                prop="lnglat"
                label="水样对比坐标:"
                placeholder="请输入或点击地图选取"
                v-model.trim="inspectPlanForm.lnglat"
              />
            </el-col>
          </el-row>
          <el-form-item>
            <div class="tmap-box-main">
              <t-amp id="inspectPlanId" ref="tMapRef" :list="pointList" @map-click="mapClick" />
            </div>
          </el-form-item>

          <el-form-item v-if="!viewId">
            <ml-list fileName="fileName" :list="inspectPlanForm.attachVos" />
          </el-form-item>

          <ml-button
            :showCancel="false"
            submitText="上传附件"
            @click-submit="showUploadDia"
            v-if="!viewId"
          >
            <!-- <ml-upload
              multiple
              autoUpload
              :showFileList="false"
              :action="action"
              :data="{ linkType: 'error' }"
              :headers="{ Authorization: toKen }"
              name="files"
              @on-success="onSuccess"
            >
              <el-button size="medium" icon="el-icon-circle-plus-outline" type="primary">
                上传附件
              </el-button>
            </ml-upload>-->
          </ml-button>

          <!-- 提交按钮 -->
          <ml-button
            v-if="!viewId"
            submitText="提交"
            submitIcon="el-icon-upload2"
            cancelIcon="el-icon-circle-close"
            cancelText="返回上一页"
            @click-submit="clickSubmitInspectPlan"
            @click-cancel="clickCancelInspectPlan"
          />
          <ml-form labelWidth="200px" style="width: 1200px; margin: 40px auto" v-if="!!viewId">
            <el-form-item>
              <ml-list
                fileName="fileName"
                :showDelete="false"
                showDown
                :list="inspectPlanForm.attachVos"
              />
            </el-form-item>
            <ml-button
              :showSubmit="false"
              cancelIcon="el-icon-circle-close"
              cancelText="返回上一页"
              @click-cancel="clickCancelInspectPlan"
            />
          </ml-form>
        </ml-form>
      </el-scrollbar>
    </ml-tip>
    <!-- 上传附件-资源管理器 -->
    <c-upload-dlg
      v-if="uploadConfig.length > 0"
      ref="cUploadDlgRef"
      v-model="uploadData"
      :config="uploadConfig"
    />
    <!-- 添加人提示框 -->
    <ml-dialog
      width="1200px"
      ref="assignedPeopleDialogRef"
      :title="isMUser"
      @clickSubmit="clickSubmitAssignedPeople"
      @clickClose="clickSubmitAssignedPeopleClose"
      @before-close="beforeClose"
    >
      <template #body>
        <div>
          <div>
            <ml-form
              :style="{ width: '1180px' }"
              inline
              labelWidth="80px"
              :model="assignedPeopleForm"
              ref="assignedPeopleFormRef"
            >
              <!-- 帐号 -->
              <ml-input
                prop="userCodeLike"
                placeholder="请输入账号"
                label="帐号:"
                v-model="assignedPeopleForm.userCodeLike"
              />
              <!-- 用户 -->
              <ml-input
                prop="userNameLike"
                placeholder="请输入用户名"
                label="用户名:"
                v-model="assignedPeopleForm.userNameLike"
                :style="{ marginRight: '40px' }"
              />
              <!-- 搜索按钮 -->
              <ml-button
                submitText="搜索"
                submitIcon="el-icon-search"
                cancelText="重置"
                cancelIcon="el-icon-refresh"
                cancelType="danger"
                @click-cancel="resetSearchData"
                @click-submit="searchFn"
              />
            </ml-form>
          </div>
          <div class="assignedPeople-table-box">
            <el-table
              :data="tabberData.data"
              style="width: 100%"
              :height="200"
              @select-all="selectAll"
              @select="select"
              :row-key="row => row.userId"
              ref="assignedPeopleTableRef"
            >
              <el-table-column type="selection" :reserve-selection="true" width="55" />
              <el-table-column align="center" label="账号" prop="userCode" min-width="30%" />
              <el-table-column
                show-overflow-tooltip
                align="center"
                label="用户名"
                prop="userName"
                min-width="15%"
              />
              <el-table-column align="center" label="单位" prop="companyName" min-width="30%" />
              <el-table-column
                show-overflow-tooltip
                align="center"
                label="职位"
                prop="job"
                min-width="20%"
              />
            </el-table>
            <!-- 分页 -->
            <ml-pagination
              :total="tabberData.total"
              :page="tabberData.page"
              v-model="tabberData.page"
              :size="tabberData.size"
              @current-change="currentChange"
              @size-change="sizeChange"
            />
          </div>
        </div>
      </template>
    </ml-dialog>
  </div>
</template>

<script>
import { computed, nextTick, onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
// import { BASEURL, FILEUPLOADURL } from '@API'
import { getTabberData, searchParams, tabberHeight, formetData } from '@/utils'
import TAmp from '../../../components/tamap'
export default {
  name: 'syTaskPlanAction',
  components: { TAmp },
  setup() {
    const { commit, getters, dispatch } = useStore()
    const route = useRoute()
    const router = useRouter()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const toKen = computed(() => getters.getToken)
    // 获取路径参数
    const { updateId, viewId } = route.query
    // 设置导航标题文本
    const content = ref(
      updateId ? '修改水样对比计划' : viewId ? '水样对比计划详情' : '添加水样对比计划'
    )
    /* 开始 用户表单 **********************************/
    // 账户表单配置
    const inspectPlanFormRef = ref()
    const regionRef = ref()
    const inspectPlanForm = reactive({
      companyId: '',
      projectId: '',
      planName: '',
      planType: '季度巡检计划',
      date: [],
      taskTime: '',
      inspectUserVos: [],
      content: '',
      attachVos: [],
      lnglat: '',
      planBusinessType: '1',
      qualityControlType: '水样对比',
      process: {
        businessId: '',
        processKey: '',
        definitionId: '',
        procInsId: '',
        businessType: '',
        processName: ''
      }
    })
    // 单位名下拉列表
    const unitOptions = ref([])

    // 水样对比类型
    const inspectionTypeData = ref([
      { label: '临时巡检计划' },
      { label: '日巡检计划' },
      { label: '周巡检计划' },
      { label: '季度巡检计划' },
      { label: '月巡检计划' },
      { label: '年巡检计划' }
    ])
    // 计划业务类型
    const planBusinessData = ref([
      // { value: '0', label: '巡检' },
      { value: '1', label: '质控' }
    ])
    // 质检类型
    const qualityTypeData = ref([
      { label: '水样对比' },
      { label: '校正' },
      { label: '多点线性核查' },
      { label: '水样对比' }
    ])

    // 所属项目配置
    const projectData = ref([])
    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = { projectStatus: 'Y' }
      if (isBusinessDepartment.value) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }
    const selectUnit = () => {
      inspectPlanForm.projectId = ''
    }
    watch(
      () => inspectPlanForm.companyId,
      newvalue => {
        getProjectData(newvalue)
      }
    )
    // 时间校验方法
    const daterangeType = ref('daterange')
    watch(
      () => inspectPlanForm.planType,
      newvalue => {
        switch (newvalue) {
          case '月水样对比计划':
            daterangeType.value = 'monthrange'
            break
          case '年水样对比计划':
            daterangeType.value = 'monthrange'
            break
          default:
            daterangeType.value = 'daterange'
            break
        }
      }
    )
    const changePlanTypeFn = () => {
      inspectPlanForm.date = []
      inspectPlanForm.taskTime = ''
    }
    // 时间校验
    const validatorTaskTime = (rule, val) => new Date(val) > new Date()
    // 坐标校验
    const validatorCoord = (rule, val) => {
      if (val) {
        let c = val.split(',')
        if (c.length === 2) {
          if (c[0] && c[1] && c[0] > 72 && c[0] < 137.9 && c[0] > 0.8 && c[1] < 55.9) {
            return true
          }
        }
      }
      return false
    }
    // 账户新增修改校验
    const inspectPlanFormRules = {
      companyId: [{ required: true, message: '请选择单位', trigger: 'blur' }],
      projectId: [{ required: true, message: '请选择所属项目', trigger: 'blur' }],
      planName: [{ required: true, message: '请输入水样对比计划名称', trigger: 'blur' }],
      planBusinessType: [{ required: true, message: '请选择计划业务类型', trigger: 'blur' }],
      qualityControlType: [{ required: true, message: '请选择质检类型', trigger: 'blur' }],
      planType: [{ required: true, message: '请输入水样对比类型', trigger: 'blur' }],
      date: [{ required: true, message: '请选择日期', trigger: 'blur' }],
      taskTime: [
        { required: true, message: '请选择生成任务时间', trigger: 'blur' },
        {
          validator: validatorTaskTime,
          message: '生成任务时间需大于当时间',
          trigger: ['blur', 'change']
        }
      ],
      inspectUserVos: [{ required: true, message: '请输入执行人', trigger: 'blur' }],
      content: [{ required: true, message: '请输入水样对比内容', trigger: 'blur' }],
      lnglat: [
        { required: true, message: '请输入或选取坐标', trigger: 'blur' },
        {
          validator: validatorCoord,
          message: '坐标不合法',
          trigger: ['blur', 'change']
        }
      ],
      process: {
        definitionId: [{ required: true, message: '请选择流程', trigger: 'blur' }]
      }
    }
    // 提交表单
    const clickSubmitInspectPlan = () => {
      inspectPlanFormRef.value.CustomFormRef.validate()
        .then(() => {
          // 处理参数
          const inspectPlanFormParams = searchParams(
            inspectPlanForm,
            ['date', 'taskTime', 'lnglat'],
            true
          )
          if (inspectPlanForm.date && inspectPlanForm.date.length > 0) {
            inspectPlanFormParams.startTime = formetData(inspectPlanForm.date[0])
            inspectPlanFormParams.endTime = formetData(inspectPlanForm.date[1])
          }
          if (inspectPlanForm.taskTime) {
            inspectPlanFormParams.taskTime = formetData(inspectPlanForm.taskTime)
          }
          if (inspectPlanForm.lnglat) {
            let lnglat = inspectPlanForm.lnglat.split(',')

            inspectPlanFormParams.longitude = Number(lnglat[0])
            inspectPlanFormParams.latitude = Number(lnglat[1])
          }

          if (content.value === '添加水样对比计划') {
            dispatch('fetchAddInspectPlan', inspectPlanFormParams).then(data => {
              if (data && data.code === 200) {
                commit('setError', {
                  status: true,
                  title: data.message,
                  message: '正在获取新的数据...',
                  type: 'success'
                })
                router.push({ path: '/syTaskPlan' })
              }
            })
          } else if (content.value === '修改水样对比计划') {
            inspectPlanFormParams.planId = updateId
            dispatch('fetchUpdateInspectPlan', inspectPlanFormParams).then(data => {
              if (data && data.code === 200) {
                commit('setError', {
                  status: true,
                  title: data.message,
                  message: '正在获取新的数据...',
                  type: 'success'
                })
                router.push({ path: '/syTaskPlan' })
              }
            })
          }
        })
        .catch(() => {})
    }
    // 取消表单
    const clickCancelInspectPlan = () => {
      router.push({ path: '/syTaskPlan' })
    }

    // 上传附件
    // const action = ref(`${BASEURL}${FILEUPLOADURL}`)
    // const onSuccess = files => {
    //   if (files.data) {
    //     inspectPlanForm.attachVos = [...inspectPlanForm.attachVos, ...files.data]
    //   }
    // }
    /* 结束 用户表单 **********************************/

    /* 2021-12-15 新版上传文件管理器 wml ******************/
    // 上传资源管理器属性
    const cUploadDlgRef = ref()
    const uploadData = ref([])
    const uploadConfig = ref([])

    watch(
      () => uploadData.value,
      newvalue => {
        inspectPlanForm.attachVos = [...inspectPlanForm.attachVos, ...newvalue]
      }
    )
    // 打开对话框
    const showUploadDia = () => {
      const { companyId, projectId } = inspectPlanForm
      uploadData.value = []
      const nArr = []
      if (companyId) {
        nArr.push(companyId)
        if (projectId) {
          nArr.push(projectId)
        }
      }
      uploadConfig.value = [...nArr]
      nextTick(() => {
        cUploadDlgRef.value.uploadDialogRef.showDialog = true
      })
    }
    /* 2021-12-15 新版上传文件管理器 wml ******************/
    // 选择负责人列表
    const isMUser = ref('')
    // 选择执行人列表
    const selectInspectUserVosData = ref()
    // 保存选择人
    const saveSelectInspectUserVosData = ref([])
    const inspectPlanFormNode = ref({})

    const tabberData = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10,
      saveSelectInspectUserVosData: [],
      isMSubmie: false,
      isISubmie: false
    })

    // 打开指派人对话框
    const assignedPeopleDialogRef = ref()

    // 获取账户列表
    const getAssignedPeopleData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(assignedPeopleForm)
      searchDataParams.userTypes = 'engineer,amiba_manager'
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetAppUsers',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0

      if (selectInspectUserVosData.value.length > 0) {
        selectInspectUserVosData.value.map(item => {
          assignedPeopleTableRef.value.toggleRowSelection(item, true)
        })
      }
    }

    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getAssignedPeopleData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }

    watch(
      () => tabberData.saveSelectInspectUserVosData,
      newvalue => {
        selectInspectUserVosData.value = newvalue
      }
    )

    // 点击添加执行人
    const clickInspectUserVosAdd = () => {
      isMUser.value = '执行人'
      selectInspectUserVosData.value = saveSelectInspectUserVosData.value
      assignedPeopleForm.companyId = inspectPlanFormNode.value.companyId
      getAssignedPeopleData()
      assignedPeopleDialogRef.value.showDialog = true
    }

    // 指派人表单
    const assignedPeopleTableRef = ref()
    const assignedPeopleFormRef = ref()
    // 指派人搜索条件
    const assignedPeopleForm = reactive({
      userCodeLike: '',
      userNameLike: '',
      companyId: ''
    })

    // 重置搜索内容
    const resetSearchData = () => {
      assignedPeopleFormRef.value.CustomFormRef.resetFields()
      tabberData.page === 1 ? getAssignedPeopleData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getAssignedPeopleData() : (tabberData.page = 1)
    }

    // 选中的添加到列表
    const getSelection = selection =>
      selection.map(item => {
        return {
          userId: item.userId,
          userName: item.userName
        }
      })
    // 选中单个
    const select = selection => {
      selectInspectUserVosData.value = getSelection(selection)
    }
    // 选中全部
    const selectAll = selection => {
      selectInspectUserVosData.value = selectInspectUserVosData.value = getSelection(selection)
    }

    // 提交选中指派人
    const clickSubmitAssignedPeople = () => {
      tabberData.isISubmie = true
      saveSelectInspectUserVosData.value = selectInspectUserVosData.value
      inspectPlanForm.inspectUserVos = selectInspectUserVosData.value

      assignedPeopleDialogRef.value.showDialog = false
      assignedPeopleTableRef.value.clearSelection()
    }
    // 取消选择指派人
    const clickSubmitAssignedPeopleClose = () => {
      if (!tabberData.isISubmie) {
        selectInspectUserVosData.value = []
      }
      tabberData.isISubmie = false
      assignedPeopleTableRef.value.clearSelection()
    }
    // 点击遮罩关闭
    const beforeClose = () => {
      assignedPeopleTableRef.value.clearSelection()
    }

    // 删除Tag
    const clickTagCloseI = index => {
      saveSelectInspectUserVosData.value.splice(index, 1)
      selectInspectUserVosData.value = []
      assignedPeopleTableRef.value.clearSelection()
    }

    // 获取列表
    // const getInspectPlans = async (params = {}) => {
    //   const { data = [] } = await getTabberData(dispatch, 'fetchGetInspectPlans', params)
    //   inspectPlansData.value = data || []
    // }

    // 地图点
    const pointList = ref([])
    // 地图取点
    const mapClick = c => {
      if (viewId) return
      inspectPlanForm.lnglat = c.join(',')
      pointList.value = [
        {
          longitude: c[0],
          latitude: c[1]
        }
      ]
    }

    const processData = ref([])
    const isNoProcess = ref(false)
    watch(
      () => inspectPlanForm.projectId,
      newValue => {
        processData.value = []
        if (newValue) {
          getProcessData()
        } else {
          inspectPlanForm.process.definitionId = ''
        }
      }
    )
    const getProcessData = () => {
      let params = {
        companyId: '',
        projectId: inspectPlanForm.projectId,
        businessType: 'inspect_task',
        pageIndex: 1,
        pageSize: 500
      }
      dispatch('fetchProjectProcessPage', params).then(res => {
        if (res.code === 200) {
          processData.value = res.data.records
          const item = processData.value.find(
            d => d.definitionId === inspectPlanForm.process && inspectPlanForm.process.definitionId
          )
          if (!item && !viewId) {
            let processDataValue = processData.value[0] || {}
            inspectPlanForm.process.definitionId = processDataValue.definitionId || ''
            inspectPlanForm.process.processKey = processDataValue.processKey || ''
            inspectPlanForm.process.businessType = processDataValue.businessType || ''
            inspectPlanForm.process.processName = processDataValue.processName || ''
          }
        }
      })
    }
    const definitionChange = value => {
      if (value) {
        const item = processData.value.find(d => d.definitionId === value)
        inspectPlanForm.process.processKey = (item && item.processKey) || ''
        inspectPlanForm.process.businessType = (item && item.businessType) || ''
        inspectPlanForm.process.processName = (item && item.processName) || ''
      } else {
        inspectPlanForm.process.processKey = ''
        inspectPlanForm.process.businessType = ''
        inspectPlanForm.process.processName = ''
      }
    }

    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {
        noCompanyCode: 'business_department'
      })
      unitOptions.value = data || []
      if (data.length > 0) {
        inspectPlanForm.companyId = data[0].companyId
      }

      if (content.value != '添加水样对比计划') {
        const planId = updateId || viewId
        await dispatch('fetchGetInspectPlan', planId).then(data => {
          if (data && data.code === 200) {
            const { startTime, endTime, attachVos, longitude, latitude } = data.data
            Object.keys(inspectPlanForm).map(item => {
              if (!['date', 'attachVos'].includes(item)) {
                inspectPlanForm[item] = data.data[item]
              }
            })
            if (startTime && endTime) {
              inspectPlanForm.date = [startTime, endTime]
            }
            if (attachVos) {
              inspectPlanForm.attachVos = attachVos
            }
            if (longitude && latitude) {
              pointList.value = [
                {
                  longitude,
                  latitude
                }
              ]
              inspectPlanForm.lnglat = longitude + ',' + latitude

              if (!inspectPlanForm.process) {
                inspectPlanForm.process = {
                  businessId: '',
                  processKey: '',
                  definitionId: '',
                  procInsId: '',
                  businessType: '',
                  processName: ''
                }
              }
            }
          }
        })
      }
      if (viewId) {
        inspectPlanFormRef.value.CustomFormRef.clearValidate()
      }
    })
    return {
      daterangeType,
      changePlanTypeFn,
      saveSelectInspectUserVosData,
      clickTagCloseI,
      inspectPlanFormRef,
      regionRef,
      content,
      inspectPlanForm,
      unitOptions,
      inspectionTypeData,
      projectData,
      inspectPlanFormRules,
      clickSubmitInspectPlan,
      clickCancelInspectPlan,
      selectUnit,
      tabberHeight,
      toKen,
      // action,
      // onSuccess,
      viewId,
      uploadData,
      showUploadDia,
      cUploadDlgRef,
      uploadConfig,
      assignedPeopleDialogRef,
      assignedPeopleForm,
      assignedPeopleFormRef,
      assignedPeopleTableRef,
      resetSearchData,
      searchFn,
      select,
      selectAll,
      clickInspectUserVosAdd,
      clickSubmitAssignedPeople,
      clickSubmitAssignedPeopleClose,
      beforeClose,
      inspectPlanFormNode,
      tabberData,
      currentChange,
      sizeChange,
      isMUser,
      pointList,
      mapClick,
      planBusinessData,
      qualityTypeData,
      definitionChange,
      processData,
      getProcessData,
      isNoProcess
    }
  }
}
</script>

<style lang="scss">
.inspectPlan-action {
  position: relative;
  .name-tag-box {
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 0 5px;
    min-height: 40px;
    max-height: 110px;
    overflow-x: hidden;
  }
  .tmap-box-main {
    width: 860px;
    height: 480px;
  }
}
</style>
