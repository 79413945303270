import { ref, nextTick } from 'vue'
import { formetData } from '@/utils'

export function useProcessHandler(dispatch, updateDetailCallback, submitFultCallback) {
  const btnCode = ref('')
  const btnName = ref('')
  const processCof = ref({})
  const customButtons = ref([])
  const formDisabled = ref(false)
  // 故障流程进度
  const deviceErrorLogsByError = ref([])

  const multipleSelection = ref(false)

  const showDistributeDialog = ref(false)
  const distributeRef = ref()
  const completedType = ref('edit')
  const showCompletedDialog = ref(false)
  const completedRef = ref()
  const sourData = ref({})
  const showSubmitDialog = ref(false)
  const submitRef = ref()
  const submitType = ref('edit')
  const showSubmitTaskDialog = ref(false)
  const submitTaskRef = ref()
  const submitTaskType = ref('edit')

  const getProcessConf = async errorId => {
    const res = await dispatch('fetchGetTaskConfRequest', { errorId })
    if (res.code === 200) {
      const data = res.data
      processCof.value = data || {}
      customButtons.value = (data && data.customButtons) || []
      formDisabled.value = !customButtons.value.find(
        d => d.value === 'act_submit_type_repeatSubmit'
      )
    }
  }
  const getDeviceErrorLogsByError = async errorId => {
    await dispatch('fetchDeviceErrorLogsByError', { errorId }).then(res => {
      if (res.code === 200) {
        let list = res.data || []
        deviceErrorLogsByError.value = list.map(d => {
          return {
            ...d,
            createTime: formetData(d.createTime),
            endTime: formetData(d.endTime)
          }
        })
      }
    })
  }

  const clickReportOrder = async item => {
    btnCode.value = item.value
    btnName.value = item.name
    if (
      ['act_submit_type_completed', 'act_submit_type_reject'].includes(item.value) ||
      item.value.indexOf('act_submit_type_approve') > -1
    ) {
      // 通过，驳回
      completedType.value = 'edit'
      showCompletedDialog.value = true
      await nextTick()
      completedRef.value.dialogRef.showDialog = true
    } else if (
      [
        'act_submit_type_delegate',
        'act_submit_type_transfer',
        'act_submit_type_distribute'
      ].includes(item.value)
    ) {
      if (item.value === 'act_submit_type_distribute') {
        multipleSelection.value = true
      } else {
        multipleSelection.value = false
      }
      // 转派，委派，抄送
      showDistributeDialog.value = true
      await nextTick()
      distributeRef.value.dialogRef.showDialog = true
    } else if (item.value === 'act_submit_type_submit') {
      // 故障提交
      submitType.value = 'edit'
      showSubmitDialog.value = true
      await nextTick()
      submitRef.value.dialogRef.showDialog = true
    } else if (item.value === 'act_submit_type_repeatSubmit') {
      // 故障重新提交
      submitFultCallback && submitFultCallback()
    } else if (item.value === 'act_submit_type_submit_task') {
      // 巡检提交报告
      submitTaskType.value = 'edit'
      showSubmitTaskDialog.value = true
      await nextTick()
      submitTaskRef.value.dialogRef.showDialog = true
    }
  }

  const successDistributedDialog = () => {
    updateDetailCallback()
    closeDistributedDialog()
  }

  const closeDistributedDialog = () => {
    showDistributeDialog.value = false
    btnCode.value = ''
    btnName.value = ''
  }

  const successCompletedDialog = () => {
    updateDetailCallback()
    closeCompletedDialog()
  }

  const closeCompletedDialog = () => {
    showCompletedDialog.value = false
    btnCode.value = ''
    btnName.value = ''
  }

  const successSubmitDialog = () => {
    updateDetailCallback()
    closeSubmitDialog()
  }

  const closeSubmitDialog = () => {
    showSubmitDialog.value = false
    submitType.value = 'edit'
    btnCode.value = ''
    btnName.value = ''
  }
  const successSubmitTaskDialog = () => {
    updateDetailCallback()
    closeSubmitTaskDialog()
  }

  const closeSubmitTaskDialog = () => {
    showSubmitTaskDialog.value = false
    submitTaskType.value = 'edit'
    btnCode.value = ''
    btnName.value = ''
  }

  const viewFromHandle = async data => {
    btnCode.value = data.buttonCode
    btnName.value = '查看'
    if (data.businessParamJson) {
      if (data.businessType === 'inspect_task') {
        submitTaskType.value = 'view'
        sourData.value = JSON.parse(data.businessParamJson)
        showSubmitTaskDialog.value = true
        await nextTick()
        submitTaskRef.value.dialogRef.showDialog = true
      } else {
        submitType.value = 'view'
        sourData.value = JSON.parse(data.businessParamJson)
        showSubmitDialog.value = true
        await nextTick()
        submitRef.value.dialogRef.showDialog = true
      }
    } else {
      completedType.value = 'view'
      showCompletedDialog.value = true
      processCof.value.formConf = JSON.parse(data.formConf)
      sourData.value = JSON.parse(data.customParamJson)
      await nextTick()
      completedRef.value.dialogRef.showDialog = true
    }
  }

  return {
    btnCode,
    btnName,
    processCof,
    customButtons,
    formDisabled,
    deviceErrorLogsByError,
    showDistributeDialog,
    distributeRef,
    completedType,
    showCompletedDialog,
    completedRef,
    sourData,
    showSubmitDialog,
    submitRef,
    submitType,
    getProcessConf,
    getDeviceErrorLogsByError,
    clickReportOrder,
    successDistributedDialog,
    closeDistributedDialog,
    successCompletedDialog,
    closeCompletedDialog,
    successSubmitDialog,
    closeSubmitDialog,
    viewFromHandle,
    showSubmitTaskDialog,
    submitTaskRef,
    submitTaskType,
    successSubmitTaskDialog,
    closeSubmitTaskDialog,
    multipleSelection
  }
}
