/**
 * 站点位置管理接口
 */

const prefix = '/dseWeb'
export default [
  // 查询列表（分页）
  { name: 'getPcsSitePage', url: `${prefix}/v1/op/pcsSite/page`, method: 'post' },
  // 新增或修改
  { name: 'insetOrUpdatePcsSite', url: `${prefix}/v1/op/pcsSite/insetOrUpdate`, method: 'post' },
  // 查询单个详情
  { name: 'getPcsSiteInfo', url: `${prefix}/v1/op//pcsSite/info`, method: 'get' },
  // 删除单个详情
  { name: 'deletePcsSite', url: `${prefix}/v1/op/pcsSite/delete`, method: 'get' },
  // 站点列表导出
  { name: 'siteExportPcsSite', url: `${prefix}/v1/op/pcsSite/siteExport`, method: 'post' },
  // 站点列表导入
  { name: 'siteImportPcsSite', url: `${prefix}/v1/op/pcsSite/siteImport`, method: 'post' },
  // 根据故障ID获取里程记录列表
  { name: 'listByErrorIdPcs', url: `${prefix}/v1/op/pcsRecord/listByErrorId`, method: 'get' }
]
