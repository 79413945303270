<template>
  <ml-dialog
    width="640px"
    ref="dialogRef"
    :title="title"
    @click-submit="saveHandle"
    @click-close="closeHandle"
  >
    <template #body>
      <ml-form
        :model="itemData"
        ref="formRef"
        :rules="formRules"
        labelWidth="120px"
        style="width: 100%"
      >
        <ml-select
          prop="userId"
          placeholder="请选择人员名称"
          :options="userOptions"
          label="运维平台用户:"
          keyName="userId"
          labelName="userName"
          valueName="userId"
          :filterable="true"
          v-model="itemData.userId"
        />
        <ml-select
          prop="employeeId"
          placeholder="每刻报销用户"
          :options="userMkOptions"
          label="每刻用户:"
          keyName="employeeId"
          labelName="employeeName"
          valueName="employeeId"
          :filterable="true"
          v-model="itemData.employeeId"
          @change="changeEmployee"
        />
      </ml-form>
    </template>
  </ml-dialog>
</template>

<script>
import { ref, reactive, onMounted, computed, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
export default {
  props: {
    editData: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const { commit, getters, dispatch } = useStore()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const title = ref('新增用户绑定')
    const dialogRef = ref(null)
    const openDialog = () => {
      dialogRef.value.open()
    }
    const closeHandle = () => {
      emit('close')
    }
    const itemData = reactive({
      id: '',
      userId: '',
      employeeId: '',
      employeeName: '',
      employeePhone: ''
    })
    watch(
      () => props.editData,
      async newvalue => {
        if (Object.keys(newvalue).length) {
          title.value = '编辑用户绑定'
          itemData.id = newvalue.id
          itemData.userId = newvalue.userId
          itemData.employeeId = newvalue.employeeId
          itemData.employeeName = newvalue.employeeName
          itemData.employeePhone = newvalue.employeePhone
          await nextTick()
        }
      },
      { immediate: true, deep: true }
    )
    const formRules = reactive({
      userId: [{ required: true, message: '请选择运维用户', trigger: 'blur' }],
      employeeId: [{ required: true, message: '请选择每刻报销用户', trigger: 'blur' }]
    })
    const userOptions = ref([])
    const userMkOptions = ref([])

    const getUserListData = () => {
      let params = {
        userId: '',
        projectId: '',
        companyId: '',
        userName: ''
      }
      dispatch('fetchGetSelectUserByParam', params).then(data => {
        if (data && data.code === 200) {
          userOptions.value = data.data || []
        }
      })
    }

    const getMkUserListData = () => {
      dispatch('fetchGetEmployeeList', {}).then(data => {
        if (data && data.code === 200) {
          userMkOptions.value = data.data || []
        }
      })
    }
    const changeEmployee = value => {
      if (value) {
        const item = userMkOptions.value.find(item => item.employeeId === value)
        if (item) {
          itemData.employeePhone = item.phoneNo
          itemData.employeeName = item.employeeName
        }
      }
    }

    const formRef = ref(null)
    const saveHandle = () => {
      formRef.value.CustomFormRef.validate().then(() => {
        dispatch('fetchInsetOrUpdatePcsUser', itemData).then(data => {
          if (data.code === 200) {
            commit('setError', {
              status: true,
              title: data.message,
              message: '正在获取新的数据...',
              type: 'success'
            })
            emit('save-success')
            closeHandle()
          }
        })
      })
    }
    onMounted(async () => {
      await getUserListData()
      await getMkUserListData()
    })

    return {
      title,
      openDialog,
      closeHandle,
      saveHandle,
      dialogRef,
      itemData,
      formRules,
      formRef,
      getUserListData,
      getMkUserListData,
      changeEmployee,
      userOptions,
      userMkOptions
    }
  }
}
</script>
