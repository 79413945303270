<template>
  <task-index typeTitle="多点线性核查"></task-index>
</template>

<script>
import taskIndex from '../components/taskIndex.vue';
export default {
  components: {
    taskIndex
  }
}
</script>
