import commonApi from './commonApi'
import loginApi from './loginApi'
import unitManageApi from './unitManageApi'
import projectInfoManageApi from './projectInfoManageApi'
import userWebApi from './userWebApi'
import userAppApi from './userAppApi'
import roleManageApi from './roleManageApi'
import equipmentApi from './equipmentApi'
import sparePartsApi from './sparePartsApi'
import sparePutRecordApi from './sparePutRecordApi'
import spareOutRecordApi from './spareOutRecordApi'
import faultServiceCenterApi from './faultServiceCenterApi'
import orderDraftApi from './orderDraftApi'
import processManageApi from './processManageApi'
import inspectPlanApi from './inspectPlanApi'
import inspectTasksApi from './inspectTasksApi'
import dtyInfosManageApi from './dtyInfosManageApi'
import projectFoldersApi from './projectFoldersApi'
import suggestRecordsApi from './suggestRecordsApi'
import satisfactionRecordsApi from './satisfactionRecordsApi'
import companyNoticesApi from './companyNoticesApi'
import quarterlyEarningsApi from './quarterlyEarningsApi'
import smsSendRecordsApi from './smsSendRecordsApi'
import eventSendRecordsApi from './eventSendRecordsApi'
import knowledgeBaseApi from './knowledgeBaseApi'
import opsReportApi from './opsReportApi'
import projectBindProcessApi from './projectBindProcessApi'
import newDeviceErrorApi from './newDeviceErrorApi'
import workflowApi from './workflowApi'
import resourceApi from './resourceApi'
import siteLocationApi from './siteLocationApi'
import mileageLedgerApi from './mileageLedgerApi'
import userBindApi from './userBindApi'

// 请求后端的地址
export const prefix = '/dseWeb'
export const BASEURL = process.env.VUE_APP_BASE_API // 开发环境

// export const BASEURL = 'https://pxxt.shopismini.com'
// export const BASEURL = 'http://192.168.0.67:8002'
// 服务器文件地址 BASEURL + '/' + FILEPREVIEWURL + '/' + url
export const FILEPREVIEWURL = `${prefix}/v1/filePreview`
// 服务器上传文件地址 BASEURL + '/' + FILEUPLOADURL + '?linkType=' + 参数
export const FILEUPLOADURL = `${prefix}/v1/fileUpload`

// 服务器上传文件地址 BASEURL + '/' + upload + '?linkType=' + 参数
export const UPLOAD = `${prefix}/v1/upload`

// 服务器上传文件地址 BASEURL + '/' + FILEUPLOADURL + '?linkType=' + 参数
export const FILEUPLOADURLHEAD = `${prefix}/v1/op/currentUserLogo`
// 服务器下载文件
export const FILEDOWNLOAD = `${BASEURL}${prefix}/v1/fileDownload`
// 服务器下载模版
export const FILEDOWNLOD = `${BASEURL}${prefix}/v1/template/download`
// 月度评价模板下载
export const TEMPLATEDOWNLOD = `${BASEURL}${prefix}/v1/op/monthEvaluateTemplate`
// 设备导出
export const DEVICEEXCELEXPORT = `${BASEURL}${prefix}/v1/op/deviceExcelExport`
// iot导出
export const DEVICEEXCELTOIOT = `${BASEURL}${prefix}/v1/op/deviceExportToIot`
export const SPAREPARTEXCELEXPORT = `${BASEURL}${prefix}/v1/op/sparePartExcelExport`
// 站点位置管理导出
export const PCSSITEXPORT = `${BASEURL}${prefix}/v1/op/pcsSite/siteExport`
// 值班总结导出
export const DUTYSUMMARYEXPORT = `${BASEURL}${prefix}/v1/op/dutySummaryAttachDownload`
// 二维码展示 + 设备ID
export const DEVICEQRCODE = `${BASEURL}${prefix}/v1/op/deviceQrCode`
// 验证码图片
export const VRRYCODE = `${BASEURL}${prefix}/v1/getVeryCode`
// 天地图服务获取城市经纬度Kdy
export const tampCityLocaltionKey = '42b3f523fee258b30fd2c35be696d8ea'

// 接口集合
export const APIS = [
  ...commonApi,
  ...loginApi,
  ...unitManageApi,
  ...projectInfoManageApi,
  ...userWebApi,
  ...userAppApi,
  ...roleManageApi,
  ...equipmentApi,
  ...sparePartsApi,
  ...sparePutRecordApi,
  ...spareOutRecordApi,
  ...faultServiceCenterApi,
  ...orderDraftApi,
  ...processManageApi,
  ...inspectPlanApi,
  ...inspectTasksApi,
  ...dtyInfosManageApi,
  ...projectFoldersApi,
  ...suggestRecordsApi,
  ...satisfactionRecordsApi,
  ...companyNoticesApi,
  ...quarterlyEarningsApi,
  ...smsSendRecordsApi,
  ...eventSendRecordsApi,
  ...knowledgeBaseApi,
  ...opsReportApi,
  ...projectBindProcessApi,
  ...newDeviceErrorApi,
  ...workflowApi,
  ...resourceApi,
  ...siteLocationApi,
  ...mileageLedgerApi,
  ...userBindApi
]
