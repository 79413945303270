<template>
  <task-index typeTitle="标样核查"></task-index>
</template>

<script>
import taskIndex from '../components/taskIndex.vue';
export default {
  components: {
    taskIndex
  },
}
</script>
