<template>
  <div class="login">
    <div class="login-form-box">
      <div class="login-form">
        <h1 class="login-form-title">东深智水运维平台 - 登录</h1>
        <content-login ref="loginRef" class="login-from-input" @click-login-btn="clickLoginBtn" />
      </div>
    </div>
    <!-- 背景粒子 -->
    <vue-particles
      color="#dedede"
      :particleOpacity="0.7"
      :particlesNumber="80"
      shapeType="circle"
      :particleSize="4"
      linesColor="#dedede"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
      style="height: 100%; width: 100%; position: absolute; left: 0; top: 0"
    />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import contentLogin from './components/contentLogin.vue'
import { ref } from 'vue'
export default {
  name: 'Login',
  components: {
    contentLogin
  },
  setup() {
    const router = useRouter()
    const { commit, dispatch } = useStore()
    const loginRef = ref()

    // 点击登录触发事件
    const clickLoginBtn = formValue => {
      dispatch('fetchLogin', formValue)
        .then(data => {
          if (data && data.code === 200) {
            commit('setError', {
              status: true,
              title: data.message,
              message: '正在获取账户信息...',
              type: 'success'
            })
            router.push({ path: '/' })
          } else {
            loginRef.value.getVeryCodeHandle()
          }
        })
        .catch(err => {
          loginRef.value.getVeryCodeHandle()
        })
    }
    return {
      clickLoginBtn,
      loginRef
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  background: radial-gradient(ellipse at top left, #699bc8 0, #b5c5d8 57%);
  height: 100%;
  display: flex;
  justify-content: center;
  &-form {
    border-radius: 15px;
    background-color: #fff;
    height: -webkit-fill-available;
    padding: 20px;
  }
  &-form-box {
    width: 355px;
    height: 374px;
    margin: calc(100vh / 2 - 182px) auto;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 15px 3px #ebeef5;
    z-index: 1;
  }
  &-form-title {
    color: $--color-primary;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    text-align: center;
    margin-bottom: 0;
    font-weight: 700;
  }
  &-from-input {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px dashed #ebeef5;
  }
}
</style>
