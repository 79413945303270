<template>
  <div class="nav-top-box">
    <div class="nav-top-box-content">
      <div class="nav-top-title">
        <i :class="foldIcon" @click="clickFoldIcon"></i>
      </div>
      <div class="nav-top-icon-box">
        <el-button
          class="meteorological-service-icon"
          icon="el-icon-cloudy"
          style="font-weight: 700"
          type="text"
          @click="meteorologicalService"
        >
          气象服务
        </el-button>
        <i
          :class="isFull ? 'ml-icon-small-screen' : 'ml-icon-full-screen'"
          class="nav-top-icon-box-full"
          @click="clickFull"
        ></i>
        <el-upload
          class="upload-demo"
          style="display: inline-block"
          :action="`${BASEURL}${FILEUPLOADURLHEAD}`"
          :show-file-list="false"
          :headers="{ Authorization: token }"
          :on-success="setLogo"
        >
          <el-avatar :size="30" :src="logos || logoUrl"></el-avatar>
        </el-upload>
        <el-dropdown class="nav-top-icon-box-user" :style="{ paddingLeft: '5px' }">
          <span class="el-dropdown-link">
            {{ name }}
            <i class="el-icon-arrow-down" :style="{ padding: '0 5px', fontWeight: '700' }"></i>
            <!-- 单位名 -->
            <!-- <span v-if="unitName"> - {{ unitName }}</span> -->
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="resetPwd">
                <i class="el-icon-edit"></i>修改密码
              </el-dropdown-item>
              <el-dropdown-item @click="clickLoginOut">
                <i class="el-icon-switch-button"></i>退出登录
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <!-- 多点登录 -->
        <el-dropdown
          v-if="showMoreLogin"
          class="nav-top-icon-box-user"
          max-height="300px"
          :style="{ padding: '0 20px 0 5px' }"
        >
          <span class="el-dropdown-link">
            <!-- {{ name }} -->
            <!-- 单位名 -->
            <span v-if="unitName"> - {{ unitName }}</span>
            <i class="el-icon-arrow-down" :style="{ padding: '0 5px', fontWeight: '700' }"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="clickNewPage(item)" v-for="item in unitOptions" :key="item">
                {{ item.companyName }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { BASEURL, FILEUPLOADURLHEAD, FILEPREVIEWURL } from '@/API'
import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue'
import screenfull from 'screenfull'
import { useRouter } from 'vue-router'
export default {
  name: 'NavTop',
  emits: ['click-login-out', 'click-reset-pwd', 'click-setlogo', 'click-meteorological-service'],
  props: {
    userId: {
      type: [String, Number]
    },
    logo: {
      type: String
    },
    foldIcon: {
      type: String,
      default: 'el-icon-s-fold'
    },
    unitName: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    const router = useRouter()
    const { getters, dispatch, commit } = useStore()
    // 获取用户信息
    const userInfo = computed(() => getters.getUser)

    // 是否显示多点登录
    const showMoreLogin = computed(() => {
      const info = userInfo.value
      const userType = info.userType

      const dis = ['proprietor', 'proprietor'] // 业主','工程师
      // 是否存在
      const is = dis.filter(item => {
        if (userType && userType.indexOf(item) !== -1) {
          return true
        }

        return false
      })

      // 存在隐藏、不存在显示
      return is.length ? false : true
    })

    // 获取token
    const token = computed(() => getters.getToken)
    const isRefresh = computed(() => getters.getIsRefresh)
    // 点击登陆按钮发起订阅
    const clickLoginOut = () => {
      context.emit('click-login-out')
    }
    // 点击重置密码按钮发起订阅
    const resetPwd = () => {
      context.emit('click-reset-pwd')
    }
    // 点击重置密码按钮发起订阅
    const clickFoldIcon = () => {
      context.emit('click-fold-icon')
    }
    // 点击重置密码按钮发起订阅
    const meteorologicalService = () => {
      context.emit('click-meteorological-service')
    }

    // 刷新页面

    const refresh = () => {
      if (!isRefresh.value) {
        commit('setIsRefresh', true)
        setTimeout(() => {
          commit('setIsRefresh', false)
          commit('setError', {
            status: true,
            message: '刷新成功',
            type: 'success'
          })
        }, 200)
      }
    }
    // 全屏
    const isFull = ref(false)
    const clickFull = () => {
      if (screenfull.isEnabled) {
        screenfull.toggle()
        refresh()
      }
    }
    const setIsFullValue = () => {
      isFull.value = screenfull.isFullscreen
    }
    screenfull.on('change', () => {
      setIsFullValue()
    })
    // 点击头像触发
    const logos = ref('')
    logos.value = props.logo ? `${BASEURL}${FILEPREVIEWURL}/${props.logo}` : ''
    // logos.value = props.logo || ''
    const setLogo = data => {
      if (data && data.code === 200) {
        logos.value = `${BASEURL}${FILEPREVIEWURL}/${data.data}`
        // logos.value = data.data
        // commit('setUser', { head: data.data })
        // dispatch('fetchUploadHead', { id: props.userId, head: data.data }).then(result => {
        //   if (result && result.code === 200) {
        //     // 更新用户头像信息
        //     commit('setError', {
        //       status: true,
        //       title: result.message,
        //       message: '正在获取新的数据...',
        //       type: 'success'
        //     })
        //   }
        // })
      }
    }
    // 跳新页面
    const clickNewPage = item => {
      const { companyId } = item || {}
      if (companyId) {
        dispatch('fetchcompanyManagerLogin', { companyId }).then(data => {
          if (data && data.code === 200) {
            const { authorization } = data.data || {}
            const pageUrl = router.resolve({
              path: '/',
              query: { authorization }
            })
            window.open(pageUrl.href, '_blank')
          }
        })
      }
    }

    const unitOptions = ref([])
    onMounted(async () => {
      // 获取单位名
      const { data } = await dispatch('fetchGetCompanyInfos')
      unitOptions.value = data || []
    })
    const logoUrl = require('../../../assets/image/avatar-default.png')
    return {
      clickLoginOut,
      resetPwd,
      logoUrl,
      setLogo,
      logos,
      BASEURL,
      FILEUPLOADURLHEAD,
      token,
      clickFoldIcon,
      clickFull,
      isFull,
      meteorologicalService,
      unitOptions,
      clickNewPage,
      userInfo,
      showMoreLogin
    }
  }
}
</script>

<style lang="scss">
.nav-top {
  &-box {
    &-content {
      display: flex;
      justify-content: space-between;
    }
  }
  &-title i {
    font-size: 25px;
    cursor: pointer;
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
  }
  &-icon-box {
    font-size: 30px;
    &-full {
      width: 45px;
      height: 45px;
      font-size: 16px;
      cursor: pointer;
      display: inline-block;
      text-align: center;
    }
    &-user {
      // margin: 0 20px 0 5px;
      // padding: 0 20px 0 5px;
      border-right: 1px solid #fff;
      // margin-right: 20px;
      .el-dropdown-link {
        color: $--color-primary;
        cursor: pointer;
        font-size: 15px;
        font-weight: 700;
      }
    }
    &-name {
      margin-left: 5px;
      font-size: 20px;
      font-weight: normal;
    }
    &-badge {
      width: 30px;
      height: 30px;
      line-height: 25px;
    }
  }
}
.meteorological-service-icon {
  .el-icon-cloudy {
    font: {
      size: 20px;
      weight: 700;
    }
  }
}
.el-avatar--circle {
  vertical-align: middle;
  cursor: pointer;
}
</style>
