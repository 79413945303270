<template>
  <div class="user-bind-box">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="站点位置管理" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="100px" :model="searchData" ref="searchDataRef">
        <!-- 名称 -->
        <ml-input
          prop="userName"
          placeholder="请输入人员名称"
          style="margin-bottom: 0"
          label="人员名称:"
          v-model="searchData.userName"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
      </ml-form>
      <div class="add-btn-box">
        <el-button icon="el-icon-plus" type="primary" @click="addHandle">新增</el-button>
      </div>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(360)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="userName" label="运维平台用户名" />
        <el-table-column align="center" prop="userCode" label="账号" />
        <el-table-column align="center" prop="employeeId" label="每刻报销员工ID" />
        <el-table-column align="center" prop="employeeName" label="每刻报销员工名称" />
        <el-table-column align="center" prop="employeePhone" label="每刻报销员工电话" />
        <el-table-column align="center" prop="createTime" label="创建时间" />
        <el-table-column align="center" label="操作" priop="createById" width="200px">
          <template #default="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              type="primary"
              @click="clickEdit(scope.$index, scope.row)"
            >
              修改
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="clickDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>

    <!-- 删除提示框 -->
    <ml-dialog
      ref="deleteUserDialogRef"
      :content="deleteUserData.content"
      @click-submit="submitDeleteUser"
    />
    <bind-user-dialog
      v-if="showDialog"
      ref="siteRef"
      :editData="editData"
      @close="closeHandle"
      @saveSuccess="saveSuccessHandle"
    ></bind-user-dialog>
  </div>
</template>
<script>
import { computed, onMounted, reactive, ref, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import bindUserDialog from './components/bindUserDialog.vue'
import { tabberHeight, searchParams, getTabberData, clearMemoryPageFn } from '@/utils'

export default {
  components: {
    bindUserDialog
  },
  setup() {
    const { commit, getters, dispatch } = useStore()
    const getMemoryPage = computed(() => getters.getMemoryPage)
    // 搜索条件
    const searchDataRef = ref()
    // 搜索数据
    const searchData = reactive({
      userName: getMemoryPage.value.searchData.userName || '',
      id: '',
      companyId: ''
    })
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: getMemoryPage.value.page || 1,
      size: getMemoryPage.value.size || 10
    })

    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      clearMemoryPageFn(commit, searchData)
      tabberData.page === 1 ? getTableData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getTableData() : (tabberData.page = 1)
    }
    /* 开始 数据列表 **********************************/
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getTableData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }
    const getTableData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData)
      const { data } = await getTabberData(
        dispatch,
        'fetchGetPcsUserPage',
        params,
        searchDataParams
      )
      tabberData.data = data.records || []
      tabberData.total = data.total || 0
    }

    // 删除
    const deleteUserDialogRef = ref()
    const deleteUserData = reactive({
      content: '确定删除该绑定用户吗？',
      id: ''
    })

    const clickDelete = (index, row) => {
      deleteUserData.id = row.id
      deleteUserDialogRef.value.showDialog = true
    }
    // 确定删除
    const submitDeleteUser = () => {
      const { id } = deleteUserData
      dispatch('fetchDeletePcsUser', { id: id }).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          tabberData.page === 1 ? getTableData() : (tabberData.page = 1)
          deleteUserDialogRef.value.showDialog = false
        }
      })
    }

    /* 开始 新增/修改 **********************************/
    const showDialog = ref(false)
    const siteRef = ref(null)
    const editData = ref({})
    const addHandle = async () => {
      showDialog.value = true
      editData.value = {}
      await nextTick()
      siteRef.value.dialogRef.showDialog = true
    }
    const closeHandle = () => {
      showDialog.value = false
    }
    const saveSuccessHandle = () => {
      searchFn()
    }
    const clickEdit = async (index, row) => {
      showDialog.value = true
      editData.value = row
      await nextTick()
      siteRef.value.dialogRef.showDialog = true
    }

    onMounted(async () => {
      getTableData()
    })
    return {
      searchDataRef,
      searchData,
      tabberData,
      resetSearchData,
      searchFn,
      getTableData,
      currentChange,
      sizeChange,
      clickDelete,
      deleteUserDialogRef,
      deleteUserData,
      submitDeleteUser,
      tabberHeight,
      showDialog,
      siteRef,
      editData,
      addHandle,
      closeHandle,
      saveSuccessHandle,
      clickEdit
    }
  }
}
</script>
<style lang="scss" scoped>
.add-btn-box {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>
