/**
 * 每刻用户绑定接口
 */

const prefix = '/dseWeb'

export default [
  // 查询列表（分页
  { name: 'getPcsUserPage', url: `${prefix}/v1/op/pcsUser/page`, method: 'post' },
  // 新增或修改
  { name: 'insetOrUpdatePcsUser', url: `${prefix}/v1/op/pcsUser/insetOrUpdate`, method: 'post' },
  // 详情
  { name: 'getPcsUserInfo', url: `${prefix}/v1/op/pcsUser/info`, method: 'get' },
  // 新增或修改
  { name: 'deletePcsUser', url: `${prefix}/v1/op/pcsUser/delete`, method: 'get' },
  // 获取每刻报销系统员工列表
  { name: 'getEmployeeList', url: `${prefix}/v1/op/pcsUser/employeeList`, method: 'get' }
]
