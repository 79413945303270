// 公共方法
import { setStoreApiDate } from '../utils'
const prefix = '/dseWeb'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    async fetchGetPcsUserPage({ commit, getters }, params) {
      const { getPcsUserPage } = getters.getHttps
      const { data } = await getPcsUserPage(params)
      return setStoreApiDate(data, commit)
    },

    async fetchInsetOrUpdatePcsUser({ commit, getters }, params) {
      const { insetOrUpdatePcsUser } = getters.getHttps
      const { data } = await insetOrUpdatePcsUser(params)
      return setStoreApiDate(data, commit)
    },
    async fetchGetPcsUserInfo({ commit, getters }, params) {
      const { getPcsUserInfo } = getters.getHttps
      const { data } = await getPcsUserInfo(params)
      return setStoreApiDate(data, commit)
    },
    async fetchDeletePcsUser({ commit, getters }, params) {
      const { deletePcsUser } = getters.getHttps
      const { data } = await deletePcsUser(params)
      return setStoreApiDate(data, commit)
    },
    async fetchGetEmployeeList({ commit, getters }, params) {
      const { getEmployeeList } = getters.getHttps
      const { data } = await getEmployeeList(params)
      return setStoreApiDate(data, commit)
    }
  }
}
