<template>
  <ml-dialog
    width="600px"
    ref="dialogRef"
    title="提交报告"
    :btnLoading="btnLoading"
    :showSubmitBtn="!formDisable"
    @click-submit="submitReportDialogSubmit"
    @click-close="closeHandle"
  >
    <template #body>
      <ml-form
        labelWidth="100px"
        style="width: 95%"
        :model="submitReportForm"
        :rules="submitReportFormRule"
        ref="submitReportFormRef"
        :disabled="formDisable"
      >
        <!-- 办结描述 -->
        <ml-date-picker
          prop="startTime"
          placeholder="请选择开始时间"
          label="开始时间:"
          type="datetime"
          valueFormat="YYYY-MM-DD HH:mm:ss"
          v-model="submitReportForm.startTime"
        />
        <ml-date-picker
          prop="endTime"
          placeholder="请选择结束时间"
          label="结束时间:"
          type="datetime"
          valueFormat="YYYY-MM-DD HH:mm:ss"
          v-model="submitReportForm.endTime"
        />
        <ml-input
          prop="content"
          placeholder="请输入内容描述"
          label="内容描述"
          type="textarea"
          :rows="5"
          v-model="submitReportForm.content"
        />

        <el-form-item>
          <ml-list fileName="fileName" :list="submitReportForm.attachVos" />
        </el-form-item>

        <ml-button :showSubmit="false" :showCancel="false">
          <ml-upload
            multiple
            autoUpload
            :showFileList="false"
            :action="action"
            :data="{ linkType: 'error' }"
            :headers="{ Authorization: toKen }"
            name="files"
            @on-success="onSubmitReportSuccess"
          >
            <el-button size="medium" icon="el-icon-circle-plus-outline" type="primary">
              上传附件
            </el-button>
          </ml-upload>
        </ml-button>
      </ml-form>
    </template>
  </ml-dialog>
</template>
<script>
import { useStore } from 'vuex'
import { computed, reactive, ref } from 'vue'
import { BASEURL, FILEUPLOADURL } from '@API'
export default {
  name: 'submitTaskDialog',
  props: {
    conf: {
      type: Object,
      default() {
        return {}
      }
    },
    btnCode: {
      type: String,
      default: ''
    },
    companyId: {
      type: String,
      default: ''
    },
    sourData: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      default: 'edit'
    },
    inspectTaskId: {
      type: String,
      default: ''
    }
  },
  emits: ['success', 'close'],
  setup(props, context) {
    const { commit, dispatch, getters } = useStore()
    const toKen = computed(() => getters.getToken)
    const formDisable = ref(props.type === 'view' ? true : false)
    // 提交报告
    const dialogRef = ref()
    const submitReportFormRef = ref()
    const submitReportForm = reactive({
      startTime: props.type === 'view' ? props.sourData.startTime : '',
      endTime: props.type === 'view' ? props.sourData.endTime : '',
      content: props.type === 'view' ? props.sourData.content : '',
      attachVos: props.type === 'view' ? props.sourData.attachVos : [],
      inspectTaskId: props.inspectTaskId
    })
    // 校验
    const submitReportFormRule = {
      startTime: [{ required: true, message: '请选择开始时间', trigger: 'blur' }],
      endTime: [{ required: true, message: '请选择结束时间', trigger: 'blur' }],
      content: [{ required: true, message: '请输入描述', trigger: 'blur' }]
    }
    const disabledStartDate = value => {
      if (submitReportForm.endTime) {
        return value.getTime() > new Date(submitReportForm.endTime).getTime()
      }
      return false
    }

    const disabledEndDate = value => {
      if (submitReportForm.startTime) {
        return value.getTime() < new Date(submitReportForm.startTime).getTime()
      }
      return false
    }
    // 上传附件
    const action = ref(`${BASEURL}${FILEUPLOADURL}`)
    const onSubmitReportSuccess = files => {
      if (files.data) {
        submitReportForm.attachVos = [...submitReportForm.attachVos, ...files.data]
      }
    }

    const dialogData = reactive({
      buttonCode: props.btnCode,
      procInsId: props.conf && props.conf.taskDto && props.conf.taskDto.processInstanceId,
      taskId: props.conf && props.conf.taskDto && props.conf.taskDto.id,
      variables: props.conf && props.conf.variables,
      comment: '',
      toUserId: '',
      customParamJson: '',
      fileList: [],
      nodeId: props.conf && props.conf.id,
      businessParamJson: '',
      created: props.conf && props.conf.taskDto && props.conf.taskDto.created,
      formConf: props.conf.formConf
    })

    let btnLoading = ref(false)
    const submitReportDialogSubmit = () => {
      submitReportFormRef.value.CustomFormRef.validate().then(() => {
        dialogData.fileList = submitReportForm.attachVos
        dialogData.comment = submitReportForm.content
        dialogData.businessParamJson = JSON.stringify(submitReportForm)
        let startTime = new Date(submitReportForm.startTime).getTime()
        let endTime = new Date(submitReportForm.endTime).getTime()
        if (startTime > endTime) {
          commit('setError', {
            status: true,
            title: '',
            message: '结束时间不能小于开始时间',
            type: 'error'
          })
          return
        }
        dispatch('fetchButtonClickRequest', dialogData)
          .then(res => {
            btnLoading.value = false
            if (res && res.code === 200) {
              commit('setError', {
                status: true,
                title: res.message,
                message: '处理成功...',
                type: 'success'
              })
              context.emit('success')
            }
          })
          .catch(() => {
            btnLoading.value = false
          })
      })
    }
    const closeHandle = () => {
      context.emit('close')
    }
    return {
      dialogRef,
      submitReportFormRef,
      submitReportForm,
      submitReportFormRule,
      disabledStartDate,
      disabledEndDate,
      submitReportDialogSubmit,
      onSubmitReportSuccess,
      action,
      toKen,
      dialogData,
      closeHandle,
      btnLoading,
      formDisable
    }
  }
}
</script>